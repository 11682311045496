import { LoaderService } from './../../servicios/loader.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.css']
})
export class SpinnerLoaderComponent implements OnInit {

  loading: boolean;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      console.log(" Cargando Spinner >>>>> ",v);
      this.loading = v;
    })
  }

  ngOnInit(): void {
  }

}
