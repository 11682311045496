import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaginaService {

  public baseUrl:string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  public obtenerPaginas(): Observable<any>{
    return this._http.get(`${this.baseUrl}Paginas/selectAll`);
  }
}
