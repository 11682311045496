import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

  public baseUrl:string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  public selectById(IdEmpleado:string):Observable<any>{
    return this._http.get(`${this.baseUrl}Empleados/selectById?IdEmpleado=${IdEmpleado}`);
  }

  public guardarRegistro (Empleado : any):Observable<any>{
    return this._http.post<any>(`${this.baseUrl}Empleados/guardar`,Empleado);
   }

  public obtenerEmpleadosByDescripcion(textoBusqueda: string ): Observable<any>{
    return this._http.get(`${this.baseUrl}Empleados/selectByDescripcion?Descripcion=${textoBusqueda}`);
  }
}
