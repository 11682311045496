import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GrupoService } from '../../servicios/grupo.service';
import { PaginaService } from '../../servicios/pagina.service';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-agregar-paginas-grupos',
  templateUrl: './agregar-paginas-grupos.component.html',
  styleUrls: ['./agregar-paginas-grupos.component.css']
})
export class AgregarPaginasGruposComponent implements OnInit {

  constructor(private _grupoService: GrupoService,
    private _paginaService: PaginaService) { }

  ngOnInit(): void {
    this.grupo = {
      IdGrupo : "",
      Descripcion : "",
      Estatus : true
    }
  }

  grupo : any ;
  grupos: any = [];
  paginas: any = [];
  paginasAsig: any = [];
  paginasDisp : any = [];
  
  mensajeModal: string ;
  resultado : any;
  IdPaginaAgregar: string ="";
  IdPaginaRemover : string = "";
  
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  menuAccionesVisible : boolean=  false;

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.grupos = [];
    this.ventanaBusqueda.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
    if (this.paginas.length == 0){
      this.consultarPaginas();
    }
  }

  public consultarPaginas(){
    this._paginaService.obtenerPaginas().subscribe(
      data => {
        this.paginas = data.result;
      }
    );
  }

  public buscarGrupos(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this._grupoService.obtenerGrupoByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.grupos = data.result;
        if (this.grupos.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItem(idGrupo){
    this.grupo = this.grupos.find(item => item.IdGrupo == idGrupo);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
    this.obtenerPaginas(this.grupo.IdGrupo);
  }

  public obtenerPaginas(IdGrupo){
    this._grupoService.obtenerPaginasPorGrupo(IdGrupo).subscribe(
      data => {
          this.paginasAsig = data.result;
          this.paginasDisp = [];
          this.paginas.forEach(element => {
              let item = this.paginasAsig.find(item => item.IdPagina == element.IdPagina);
              if (item == null){
                this.paginasDisp.push(element);
              }
          }); 
        }
    );
  }

  public cancelar(){
    this.grupo = {
      IdGrupo : "",
      Descripcion : "",
      Estatus : true
    }
    this.mostrarBotonesFooter = false;
    this.paginasAsig = this.paginasDisp = [];
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public guardar(){
    
    this._grupoService.guardarPaginasAGrupo(this.grupo.IdGrupo, this.paginasAsig).subscribe(
      data=>{
            // this.resultado = data.result;
            if (data.msg != ""){
              this.lModalExito = data.msg.indexOf("correctamente") >= 0;
              this.asignarEstiloModal();
              this.mensajeModal = data.msg;
              this.largeModal.show();
              this.cancelar();
            }
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.cancelar();
      });

  }

  public nuevo(){
    this.grupo = {
      IdGrupo : "",
      Descripcion : "",
      Estatus : true
    }
   
    this.mostrarBotonesFooter = true;
  }

  public removerItem(){
    if (this.IdPaginaRemover !== ""){
      let item = this.paginasAsig.find(item => item.IdPagina == this.IdPaginaRemover);
      let index = this.paginasAsig.indexOf(item);
      this.paginasAsig.splice(index,1);
      this.paginasDisp.push(item);
      this.IdPaginaRemover = "";
    }
  }

  public removerItems(){
    this.IdPaginaAgregar = this.IdPaginaRemover = "";
    this.paginasDisp = this.paginasDisp.concat(this.paginasAsig);
    this.paginasAsig = [];
  }

  public agregarItem(){
    if (this.IdPaginaAgregar !== ""){
      let item = this.paginasDisp.find(item => item.IdPagina == this.IdPaginaAgregar);
      let index = this.paginasDisp.indexOf(item);
      this.paginasDisp.splice(index,1);
      this.paginasAsig.push(item);
      this.IdPaginaAgregar = "";
    }
  }

  public agregarItems(){
    this.IdPaginaAgregar = this.IdPaginaRemover = "";
    this.paginasAsig = this.paginasAsig.concat(this.paginasDisp);
    this.paginasDisp = [];
  }

  public seleccionarItemAgregar(event){
    this.IdPaginaAgregar = event.target.value ;
  }

  public seleccionarItemRemover(event){
    this.IdPaginaRemover = event.target.value ;
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success")
      else
      jQuery("#ventanaModal").addClass("modal-danger")

  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }
}
