import { Component, OnInit, ViewChild } from '@angular/core';
import { GrupoService } from '../../servicios/grupo.service';
import * as jQuery from 'jquery';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})

export class GruposComponent implements OnInit{
  modo :string = "";
  grupo : any ;
  idGrupo : string = "";
  descripcion: string = "";
  busqueda: string;
  mensajeModal: string ;
  resultado : any;
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  grupos: any = [];
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;
  
  mostrarBotonesFooter : boolean = false;

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  constructor(private _grupoService: GrupoService) {
  }  

  public ngOnInit(): void {
    jQuery("#btnOpciones").on("click", function(){
      return true;
    })
  }

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.grupos = [];
    this.ventanaBusqueda.show();
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public buscarGrupos(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
    this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this._grupoService.obtenerGrupoByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.grupos = data.result;
        if (this.grupos.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public consultar(textoBusqueda){
    this._grupoService.obtenerGrupoById(textoBusqueda).subscribe(
      data => {
        this.grupo = data.result;
        if (this.grupo != null){
          this.asignarValores();
          this.mostrarBotonesFooter = true;
        }
        else{
          this.mensajeModal = "No se encontró un grupo con el id proporcionado";
          this.largeModal.show();
          this.cancelar();
        }
      },
      error => { console.log(error); }
    );
  }

  public seleccionarItem(idGrupo){
    this.grupo = this.grupos.find(item => item.IdGrupo == idGrupo);
    this.ventanaBusqueda.hide();
    this.asignarValores();
    this.mostrarBotonesFooter = true;
  }

  public asignarValores(){
    this.idGrupo = this.grupo.IdGrupo;
    this.descripcion = this.grupo.Descripcion;
    jQuery("#estatus").prop("checked", (this.grupo.Estatus == 1 ? true : false));
    this.modo = "M";
    this.mostrarBotonesFooter = true;
  }

  public cancelar(){
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    this.modo = "V";
    this.idGrupo = "";
    this.descripcion = "";
    this.mostrarBotonesFooter = false;
  }

  public guardar(){
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    this.grupo = {};
    this.grupo.idGrupo = this.idGrupo;
    this.grupo.descripcion = this.descripcion;
    if (this.descripcion  == ""){
      this.lModalExito = false;
      this.mensajeModal = "El campo Descripción es requerido";
      this.asignarEstiloModal();
      this.largeModal.show();
      return;
    }
    this.grupo.estatus = jQuery("#estatus").prop("checked") == true ? 1 :0;

    this.mostrarBotonesFooter = false;
    this._grupoService.guardarRegistro(this.grupo).subscribe(
      data=>{
               this.resultado = data.result;
               if (data.msg != ""){
                   this.lModalExito = data.msg.indexOf("correctamente") >= 0;
                 this.asignarEstiloModal();
                 this.mensajeModal = data.msg;
                 this.largeModal.show();
               }
               if (data.result != null){
                this.idGrupo = this.resultado.IdGrupo;
                this.descripcion = this.resultado.Descripcion;
                jQuery("#estatus").prop("checked", (this.resultado.Estatus == 1 ? true : false));
               }
      },
      error=>{

      },
      ()=>{
        this.mostrarBotonesFooter = false;
      });

  }

  public nuevo(){
    this.idGrupo = "0";
    this.descripcion = "";
    jQuery("#estatus").prop("checked", true);
    this.mostrarBotonesFooter = true;
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }
}
