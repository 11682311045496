import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from '../../servicios/clientes.service';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
  @ViewChild('ventanaBusquedaClientes', {static:false}) public ventanaBusquedaClientes: ModalDirective;

  public idCliente: string = '';
  public statusCliente: string ='';
  public nombreCliente: string ='';
  public obraCliente:string ='';
  public prototipoCliente:string='';
  public substatusCliente:string='';
  public busquedaClienteMensaje : string = '';
  public clientes:any[] =[];
  public sinResultados: boolean = false;
  public construcciones: any[] = [];
  private ubicar: boolean = false;
  private idUsuario: number;

  public clienteSeleccionado: any = {
    Id_CRM: '',
    Nombre: '',
    Obra_Id: '',
    Obra: '',
    Prototipo_Venta: '',
    Estatus_CRM: '',
    Sub_Estatus_CRM: '',
    Cliente_Id: '',
    Prototipo_Venta_Id: '',
  };

  private statusValido:string;

  constructor(private clienteServicio: ClientesService) {
    this.idUsuario = JSON.parse(sessionStorage.getItem("usuario")).IdUsuario;
  }

  public ngOnInit(): void { }


  /* public buscarCliente(){
    if(this.idCliente == ''){
      this.busquedaClienteMensaje ='Ingrese id de Cliente'
    }
    else{
      this.clienteServicio.obtenerCliente(this.idCliente).subscribe(e =>{
        
        console.log(this.clientes)


  public limpiarTodo(): void {
    this.clienteSeleccionado = {
      Id_CRM: '',
      Nombre: '',
      Obra_Id: '',
      Obra: '',
      Prototipo_Venta: '',
      Estatus_CRM: '',
      Sub_Estatus_CRM: '',
      Cliente_Id: '',
      Prototipo_Venta_Id: '',
    };

    this.construcciones = [];
  }

  public buscarModal() {
    this.sinResultados = false;
    this.clientes = [];
    this.ventanaBusquedaClientes.show();
  }

  public buscarCliente(): void {
    let params = null;

    if (this.idCliente != null && this.idCliente != '') {
      params = {nombre: this.idCliente};
    }

    this.clienteServicio.buscarCliente(params).subscribe(
      (response: any) => {
        this.sinResultados = response.length == 0;
        this.clientes = response;
        this.clientes.forEach(e =>{
          this.statusValido = e['Estatus_CRM'];
          if(this.statusValido != 'Ubicado' && 'Recibido' && 'Con Recurso' && 'Escriturado')
          {
            Swal.fire({
              title:'Cliente sin estatus valido para ubicar',
              html:'',
              icon:'info',
              confirmButtonText:'Aceptar',
              confirmButtonColor: '#3085d6',
            }
            )
          }
        })
      },
      (error: any) => {
        
       }
    );
  }

  public seleccionarConstruccion(construccionItem: any): void {
    if (construccionItem.seleccionado) {
      construccionItem.seleccionado = false;
    } else {
      this.construcciones.forEach((item: any) => { item.seleccionado = false; });
      construccionItem.seleccionado = true;
    }
    
  } */

  public limpiarTodo(): void {
    this.clienteSeleccionado = {
      Id_CRM: '',
      Nombre: '',
      Obra_Id: '',
      Obra: '',
      Prototipo_Venta: '',
      Estatus_CRM: '',
      Sub_Estatus_CRM: '',
      Cliente_Id: '',
      Prototipo_Venta_Id: '',
    };

    this.construcciones = [];
  }


  /*public seleccionarCliente(clienteItem: any): void {
    this.ventanaBusquedaClientes.hide();

    this.clienteSeleccionado.Id_CRM = clienteItem.Id_CRM;
    this.clienteSeleccionado.Nombre = clienteItem.Nombre;
    this.clienteSeleccionado.Obra_Id = clienteItem.Obra_Id;
    this.clienteSeleccionado.Obra = clienteItem.Obra;
    this.clienteSeleccionado.Prototipo_Venta = clienteItem.Prototipo_Venta;
    this.clienteSeleccionado.Estatus_CRM = clienteItem.Estatus_CRM;
    this.clienteSeleccionado.Sub_Estatus_CRM = clienteItem.Sub_Estatus_CRM
    this.clienteSeleccionado.Cliente_Id = clienteItem.Cliente_Id;
    this.clienteSeleccionado.Prototipo_Venta_Id = clienteItem.Prototipo_Venta_Id;

    this.obtenerConstruccionDeClienteUbicado();
  }*/


  public buscarModal() {
    this.sinResultados = false;
    this.clientes = [];
    this.ventanaBusquedaClientes.show();
  }

  public buscarCliente(): void {
    let params = null;

    if (this.idCliente != null && this.idCliente != '') {
      params = {nombre: this.idCliente};
    }

    this.clienteServicio.buscarCliente(params).subscribe(
      (response: any) => {
        this.sinResultados = response.length == 0;
        this.clientes = response;
      },
      (error: any) => { }
    );
  }

  public seleccionarConstruccion(construccionItem: any): void {
    if (construccionItem.seleccionado) {
      construccionItem.seleccionado = false;
    } else {
      this.construcciones.forEach((item: any) => { item.seleccionado = false; });
      construccionItem.seleccionado = true;
    }
  }

  public seleccionarCliente(clienteItem: any): void {
    this.ventanaBusquedaClientes.hide();

    this.clienteSeleccionado.Id_CRM = clienteItem.Id_CRM;
    this.clienteSeleccionado.Nombre = clienteItem.Nombre;
    this.clienteSeleccionado.Obra_Id = clienteItem.Obra_Id;
    this.clienteSeleccionado.Obra = clienteItem.Obra;
    this.clienteSeleccionado.Prototipo_Venta = clienteItem.Prototipo_Venta;
    this.clienteSeleccionado.Estatus_CRM = clienteItem.Estatus_CRM;
    this.clienteSeleccionado.Sub_Estatus_CRM = clienteItem.Sub_Estatus_CRM
    this.clienteSeleccionado.Cliente_Id = clienteItem.Cliente_Id;
    this.clienteSeleccionado.Prototipo_Venta_Id = clienteItem.Prototipo_Venta_Id;

    this.obtenerConstruccionDeClienteUbicado();
  }

  private obtenerConstruccionDeClienteUbicado(): void {
    this.construcciones = [];

    this.clienteServicio.obtenerConstruccionDeClienteUbicado(this.clienteSeleccionado.Cliente_Id).subscribe(
      (response: any) => {

        console.log(response.data)

        if (response.data == null) {
          this.ubicar = true;
          this.obtenerConstrucciones();
        } else {
          this.ubicar = false;
          response.data.seleccionado = true;
          this.construcciones.push(response.data);
        }
      },
      (error: any) => { }
    );
  }

  private obtenerConstrucciones(): void {
    let params = {
      obra_id: this.clienteSeleccionado.Obra_Id,
      prototipo_venta_id: this.clienteSeleccionado.Prototipo_Venta_Id,
    };

    this.clienteServicio.obtenerConstrucciones(params).subscribe(
      (response: any) => {
        this.construcciones = response.map((item: any) => {
          item.seleccionado = false;
          return item;
        });
      },
      (error: any) => { }
    );
  }

  public guardar(): void {

    if (this.ubicar) {
      // Ubicar cliente, si es que hay un item seleccionado.

      let itemSeleccionado = null;


      for (let item of this.construcciones) {
        if (item.seleccionado) {
          itemSeleccionado = item;
          break;

        }else{
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'warning',
            title: 'SELECCIONA UNA CONSTRUCCIÓN'
          })
          break;

        }
      }

      if (itemSeleccionado != null) {
        this.ubicarCliente(itemSeleccionado.Sembrado_Construccion_Id);


        let timerInterval
        Swal.fire({
          title: 'Ubicando cliente',
          html: 'procesando...',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              //b.textContent = Swal.getTimerLeft()
            }, 400)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })

      }
    } else if (!this.construcciones[0].seleccionado) {
      // Desubicar cliente, aqui el grid tendra solamente un registro.

      this.desubicarCliente(this.construcciones[0].Sembrado_Construccion_Id);


        let timerInterval
        Swal.fire({
          title: 'Desubicando cliente',
          html: 'procesando...',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              //b.textContent = Swal.getTimerLeft()
            }, 400)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
    }
  }



    
  


  private ubicarCliente(Sembrado_Construccion_Id: number): void {
    let data = {
      key_cliente: this.clienteSeleccionado.Cliente_Id,
      key_sembrado_construccion: Sembrado_Construccion_Id,
      key_usuario_ubicacion: this.idUsuario,

      accion: 'U', // U = Ubicar | D = Desubicar
      status_CRM: this.clienteSeleccionado.Estatus_CRM,
      substatus_CRM: this.clienteSeleccionado.Sub_Estatus_CRM   

    };

    // Esta funcion sirve para ubicar y desubicar, depende del parametro "accion".
    this.clienteServicio.ubicarCliente(data).subscribe(
      (response: any) => {
        this.actualizarClienteSeleccionado();
      },
      (error: any) => {
        if (error.error.lote_ubicado != undefined) {
          alert(error.error.lote_ubicado);
        }
      }
    );
  }

  private desubicarCliente(Sembrado_Construccion_Id: number): void {
    let data = {
      key_cliente: this.clienteSeleccionado.Cliente_Id,
      estatus_crm: this.clienteSeleccionado.Estatus_CRM,
      key_sembrado_construccion: Sembrado_Construccion_Id,
      // key_usuario_ubicacion: this.idUsuario,
      accion: 'D' // U = Ubicar | D = Desubicar
    };

    // Esta funcion sirve para ubicar y desubicar, depende del parametro "accion".
    this.clienteServicio.ubicarCliente(data).subscribe(
      (response: any) => {
        this.actualizarClienteSeleccionado();
      },
      (error: any) => {
        if (error.error.lote_ubicado != undefined) {
          alert(error.error.lote_ubicado);
        }
      }
    );
  }

  // Aqui simulamos que el usuario busco y selecciono nuevamente el mismo
  // cliente en la modal de busqueda. De esta forma se actualizaran los
  // datos en la pantalla.
  private actualizarClienteSeleccionado(): void {
    let params = {nombre: this.clienteSeleccionado.Cliente_Id};

    this.clienteServicio.buscarCliente(params).subscribe(
      (response: any) => {
        let encontrado = false;

        for (let clienteItem of response) {
          if (clienteItem.Cliente_Id == this.clienteSeleccionado.Cliente_Id) {
            encontrado = true;
            this.clienteSeleccionado.Id_CRM = clienteItem.Id_CRM;
            this.clienteSeleccionado.Nombre = clienteItem.Nombre;
            this.clienteSeleccionado.Obra_Id = clienteItem.Obra_Id;
            this.clienteSeleccionado.Obra = clienteItem.Obra;
            this.clienteSeleccionado.Prototipo_Venta = clienteItem.Prototipo_Venta;
            this.clienteSeleccionado.Estatus_CRM = clienteItem.Estatus_CRM;
            this.clienteSeleccionado.Sub_Estatus_CRM = clienteItem.Sub_Estatus_CRM
            this.clienteSeleccionado.Cliente_Id = clienteItem.Cliente_Id;
            this.clienteSeleccionado.Prototipo_Venta_Id = clienteItem.Prototipo_Venta_Id;
            break;
          }
        }

        if (encontrado) {
          this.obtenerConstruccionDeClienteUbicado();
        } else {
          this.clienteSeleccionado = {
            Id_CRM: '',
            Nombre: '',
            Obra_Id: '',
            Obra: '',
            Prototipo_Venta: '',
            Estatus_CRM: '',
            Sub_Estatus_CRM: '',
            Cliente_Id: '',
            Prototipo_Venta_Id: '',
          };

          this.construcciones = [];
        }
      },
      (error: any) => { }
    );
  }

}
