import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Usuario } from '../modelos/usuario.model';


@Injectable({
    providedIn: "root"
})

export class UsuarioService{
    
    public baseUrl:string = environment.apiUrl;
    public _usuario: Usuario;

    public constructor (private _http: HttpClient, private router: Router){}

    public ingresar (username:string, password:string):Observable<any>{
        return this._http.get(`${this.baseUrl}Usuarios/login?username=${username}&password=${password}`);
    }

    public getUserLogged(){
        if (sessionStorage.getItem("usuario") === null ||
        sessionStorage.getItem("usuario") == undefined){
            this.router.navigate(["/Login"]);
            return;
        }
        this._usuario = JSON.parse(sessionStorage.getItem("usuario"));
        return this._usuario;
    }

    public logOut(){
        sessionStorage.removeItem("usuario");
        this.router.navigate(["/Login"]);
    }

    public selectById(IdUsuario:string):Observable<any>{
        return this._http.get(`${this.baseUrl}Usuarios/selectById?IdUsuario=${IdUsuario}`);
    }

    public bloquearUsuario(Username):Observable<any>{
        return this._http.get(`${this.baseUrl}Usuarios/bloquearUsuario?username=${Username}`);
    }

    public obtenerUsuarios():Observable<any>{
        return this._http.get(`${this.baseUrl}Usuarios/obtenerUsuarios`);
    }

    public selectByDescripcion(Descripcion: string):Observable<any>{
        return this._http.get(`${this.baseUrl}Usuarios/selectByDescripcion?Descripcion=${Descripcion}`);
    }
    
    public guardar(Usuario: any):Observable<any>{
        return this._http.post<any>(`${this.baseUrl}Usuarios/guardar`, Usuario);
    }

    public actualizarPassword(IdUsuario: number, Password : string):Observable<any>{
        return this._http.post<any>(`${this.baseUrl}Usuarios/actualizarPassword`, {IdUsuario, Password});
    }

    public validarAlMenosUnNumero(valor:string): boolean{
        let patron = /[0-9]/;
        return patron.test(valor);
    }

    public validarAlMenosUnaMinuscula(valor:string): boolean{
        let patron = /[a-z]/;
        return patron.test(valor);
    }

    public validarAlMenosUnaMayuscula(valor:string): boolean{
        let patron = /[A-Z]/;
        return patron.test(valor);
    }
}
