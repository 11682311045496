import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SucursalService {

  public baseUrl:string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  public obtenerSucursalById(IdSucursal: any):Observable<any>{
    return this._http.get(`${this.baseUrl}Sucursales/selectById?IdSucursal=${IdSucursal}`);
  }

  public obtenerSucursalByIdEmpresa(IdEmpresa: any):Observable<any>{
    return this._http.get(`${this.baseUrl}Sucursales/selectByIdEmpresa?IdEmpresa=${IdEmpresa}`);
  }

  public guardarRegistro(Sucursal : any):Observable<any>{
    return this._http.post<any>(`${this.baseUrl}Sucursales/guardar`,Sucursal);
  }

  public obtenerSucursalesByDescripcion(textoBusqueda: string ): Observable<any>{
    return this._http.get(`${this.baseUrl}Sucursales/selectByDescripcion?Descripcion=${textoBusqueda}`);
  }

  public obtenerSucursales(): Observable<any>{
    return this._http.get(`${this.baseUrl}Sucursales/selectAll`);
  }

  public obtenerSucursalesPorUsuarioYEmpresa(IdUsuario: string, IdEmpresa: number): Observable<any>{
    return this._http.get(`${this.baseUrl}Sucursales/selectSucursalesPorUsuario?IdUsuario=${IdUsuario}&IdEmpresa=${IdEmpresa}`);
  }
  
  public guardarSucursalesAUsuario(IdUsuario: number,IdEmpresa: number, Sucursales:any): Observable<any>{
    let valores = {IdUsuario, IdEmpresa, Sucursales};
    return this._http.post<any>(`${this.baseUrl}Sucursales/guardarSucursalesAUsuarios`, valores);
  }
}
