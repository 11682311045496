import { Component, OnInit,  ViewChild } from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-boton',
  templateUrl: './boton.component.html',
  styleUrls: ['./boton.component.css']
})
export class BotonComponent implements OnInit {
  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;
  constructor() {
    
   }

  ngOnInit(): void {
  }

}
