import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmpleadoService } from '../../servicios/empleado.service';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-empleados',
  templateUrl: './empleados.component.html',
  styleUrls: ['./empleados.component.css']
})
export class EmpleadosComponent implements OnInit {

  busqueda: string;
  empleado: any = {};
  mensajeModal: string ;
  resultado : any;
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;
 
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  empleados: any = [];

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  constructor(private empleadoService: EmpleadoService ) { }

  ngOnInit(): void {
    this.empleado = {
      IdEmpleado : "",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
    }
  }

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.empleados = [];
    this.ventanaBusqueda.show();
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public buscarEmpleados(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.empleadoService.obtenerEmpleadosByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        console.log(data)
        this.empleados = data.result;
        if (this.empleados.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItem(IdEmpleado){
    this.empleado = this.empleados.find(item => item.IdEmpleado == IdEmpleado);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
  }

  public cancelar(){
    this.empleado = {
      IdEmpleado : "",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
    }
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public guardar(){
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    this.mensajeModal = "";
    if (this.empleado.Nombre  == ""){
      this.mensajeModal = "El campo Nombre es requerido";
    }
    if (this.empleado.ApellidoPaterno  == ""){
      this.mensajeModal = "El campo Apellido Paterno es requerido";
    }
    if (this.empleado.ApellidoMaterno  == ""){
      this.mensajeModal = "El campo Apellido Materno es requerido";
    }
    if (this.mensajeModal != ""){
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.largeModal.show();
      return;
    }

    this.mostrarBotonesFooter = false;
    this.empleadoService.guardarRegistro(this.empleado).subscribe(
      data=>{
        console.log(data);
          this.resultado = data.result;
          if (data.msg != ""){
            this.lModalExito = data.msg.indexOf("correctamente") >= 0;
            this.asignarEstiloModal();
            this.mensajeModal = data.msg;
            this.largeModal.show();
          }
          if (data.result != null){
            this.empleado = data.result;
          }
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.mostrarBotonesFooter = false;
        this.cancelar();
      });

  }

  public nuevo(){
    this.empleado = {
      IdEmpleado : "0",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
    }
    this.mostrarBotonesFooter = true;
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }
}
