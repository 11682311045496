import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { allowedNodeEnvironmentFlags } from 'process';
import { UsuarioService } from '../../servicios/UsuarioService';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styles: [
    `
  .alert {
    background-color: #009688;
    border-color: #00695C;
    color: #fff;
  }
  `
  ],
  providers: [UsuarioService]
})

export class LoginComponent implements OnInit {
  public usuario: any;
  public username:string;
  public password:string;
  public mensajeError: string = "";
  public maximoIntentos : number = environment.maximoIntentosLogin;
  public numeroIntentos : number = 0;

  constructor(private _usuarioService: UsuarioService, 
    private router: Router){
    this.username = "";
    this.password = "";
  }

  public ngOnInit(){
   
  }

  public ingresar(){
    this.mensajeError = "";
    if (this.username == ""){
      this.mensajeError = "Proporcione su usuario";
      return;
    }
    if (this.password == ""){
      this.mensajeError = "Proporcione un password";
      return;
    }
    this.mensajeError = "";
    this._usuarioService.ingresar(this.username, this.password).subscribe(
      data => {
        if (data.msg != ""){
          this.mensajeError = data.msg;
          if (this.mensajeError.indexOf("incorrecto") >= 0){
            this.numeroIntentos = parseInt(sessionStorage.getItem(this.username));
            if (isNaN(this.numeroIntentos)){
              this.numeroIntentos = 1;
            }
            sessionStorage.setItem(this.username,  (this.numeroIntentos + 1).toString());

            if (this.numeroIntentos >= this.maximoIntentos){
              sessionStorage.removeItem(this.username);
              this._usuarioService.bloquearUsuario(this.username).subscribe(
                resp=>{
                  if (resp.msg != ""){
                    this.mensajeError = resp.msg;
                    sessionStorage.removeItem("usuario");
                  }
                }
              );
            }
          }
          sessionStorage.removeItem("usuario");
        }
        else{
          this.usuario = data.result;
          sessionStorage.removeItem(this.username);
          sessionStorage.setItem("usuario", JSON.stringify(this.usuario));
          this.router.navigate(["/Inicio"]);
        }
      }
    );
  }

 }
