import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  public baseUrl:string = environment.apiUrl;
   infoCliente = [
    {id:'12', 
    nombre:'Felix Tafoya', 
    obra:'1', 
    prototipo:'plan rapido',
    status:'ubicado', 
    substatus:'con recursos'
  },
    {
      id:'15', 
      nombre:'Antonio Herrera', 
      obra:'2', 
      prototipo:'ágil',
      status:'', 
      substatus:''
    }];

    objetObservable = of();

    valoresiD = [];

  constructor(private _http:HttpClient) { }

  public obtenerCliente(id:string):Observable<any>{
    this.infoCliente.forEach(e =>{
      this.valoresiD = [e['id']];
      for (let i = 0; i < this.valoresiD.length; i++){
         let idArreglo =this.valoresiD[i]
        console.log[i]
        if(id == idArreglo){
          /*console.log(i)
          console.log(this.infoCliente[i])*/
          this.objetObservable = of(this.infoCliente[i]);
        }
      }
    })

    return this.objetObservable;
  }



  public buscarCliente(params: any): Observable<any> {
    if (params == null) {
      return this._http.get(this.baseUrl + 'clientes/buscar');
    } else {
      return this._http.get(
        this.baseUrl + 'clientes/buscar',
        {params: new HttpParams({fromObject: params})}
      );
    }
  }

  public obtenerConstruccionDeClienteUbicado(clienteId: string): Observable<any> {
    return this._http.get(
      this.baseUrl + 'construcciones/buscar-ubicado',
      {params: new HttpParams({fromObject: {cliente_id: clienteId}})}
    );
  }

  public obtenerConstrucciones(params: any): Observable<any> {
    return this._http.get(
      this.baseUrl + 'construcciones/buscar',
      {params: new HttpParams({fromObject: params})}
    );
  }

  public ubicarCliente(data: any): Observable<any> {
    return this._http.post(
      this.baseUrl + 'clientes/ubicar',
      data,
      {}
    );
  }



}



