import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { UsuarioService } from '../../servicios/UsuarioService';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.css']
})
export class CambiarPasswordComponent implements OnInit {

  nombreUsuario : string;
  password: string = "";
  confirmarPassword: string = "";
  usuario: any;
  mensajeModal: string ;
  lModalExito: boolean = true;
  menuAccionesVisible : boolean=  false;
  
  @ViewChild('largeModal', {static: false})
  public largeModal: ModalDirective;

  numeroMinimoCaracteres : number = environment.numMinimoCarateresPassword;
  contieneNcaracteres : boolean = false;
  contieneMayuscula: boolean = false;
  contieneMinuscula: boolean = false;
  contieneNumero: boolean = false;

  constructor(private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.usuario = this.usuarioService.getUserLogged();
    this.nombreUsuario  = this.usuario.Login;
  }

  public guardar(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
    var mensaje:string ="";   
    if (this.password == ""){
      mensaje = "El campo Nuevo Password es requerido";
    }
    else if (this.confirmarPassword == ""){
      mensaje = "El campo Confirmar Password es requerido";
    }
    else if (this.password != this.confirmarPassword){
      mensaje = "Las claves no coinciden";
    }
    if (!this.contieneMayuscula || !this.contieneMinuscula || !this.contieneNumero || !this.contieneNcaracteres){
      mensaje = "El contraseña proporcionada no cumple con las reglas mínimas requeridas";
    }
    if (mensaje != ""){
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.mensajeModal = mensaje;
      this.largeModal.show();
      return;
    }
    
    this.usuarioService.actualizarPassword(this.usuario.IdUsuario, this.password)
      .subscribe(
        data=>{
          if (data.msg != ""){
            this.lModalExito = data.msg.indexOf("correctamente") >= 0;
            this.asignarEstiloModal();
            this.mensajeModal = data.msg;
            this.largeModal.show();
            this.cancelar();
          }
        },
        error => console.log(error),
        ()=>{  }
      );
  }

  public validarPassword(event){
    this.contieneNcaracteres = event.target.value.length >= this.numeroMinimoCaracteres;
    this.contieneMayuscula = this.usuarioService.validarAlMenosUnaMayuscula(event.target.value);
    this.contieneMinuscula = this.usuarioService.validarAlMenosUnaMinuscula(event.target.value);
    this.contieneNumero = this.usuarioService.validarAlMenosUnNumero(event.target.value);
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public cancelar(){
    this.contieneMayuscula = this.contieneMinuscula =
    this.contieneNcaracteres = this.contieneNumero = false;
    this.password = this.confirmarPassword = "";
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }
}
