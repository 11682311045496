import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmpresaService } from '../../servicios/empresa.service';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {
  
  busqueda: string;
  empresa : any ;
 
  mensajeModal: string ;
  resultado : any;
  mostrarBotonesFooter : boolean = false;
  textoVentanaBusqueda: string = "";
  empresas: any = [];
  resultadoBusquedaMensaje : string = "";
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  constructor(private _empresaService: EmpresaService) { 

  }

  public ngOnInit(): void {
    this.empresa = {
      IdEmpresa : "",
      RazonSocial : "",
      NombreComercial: ""
    };
  }

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.empresas = [];
    this.ventanaBusqueda.show();
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public buscarEmpresas(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
    this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this._empresaService.obtenerEmpresasByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.empresas = data.result;
        if (this.empresas.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItem(IdEmpresa){
    this.empresa = this.empresas.find(item => item.IdEmpresa == IdEmpresa);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
  }

  public cancelar(){
    this.empresa = {
      IdEmpresa : "",
      RazonSocial : "",
      NombreComercial: ""
    };
    this.lModalExito = true;
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public guardar(){
    let mensaje = "";
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    if (this.empresa.RazonSocial == ""){
      mensaje = "El campo Razón Social es requerido";
    }
    else if (this.empresa.NombreComercial == ""){
      mensaje = "El campo Nombre Comercial es requerido";
    }
    if (mensaje != ""){
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.mensajeModal = mensaje;
      this.largeModal.show();
      return;
    }
    this.mostrarBotonesFooter = false;
    this._empresaService.guardarRegistro(this.empresa).subscribe(
      data=>{
        this.resultado = data.result;
        if (data.msg != ""){
          this.lModalExito = data.msg.indexOf("correctamente") >= 0;
          this.asignarEstiloModal();
          this.mensajeModal = data.msg;
          this.largeModal.show();
          this.cancelar();
        }
        if (data.result != null){
          this.empresa = data.result;
        }
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.mostrarBotonesFooter = false;
        this.cancelar();
      });
  }

  public nuevo(){
    this.empresa = {
      IdEmpresa : "0",
      RazonSocial : "",
      NombreComercial: ""
    };
    this.mostrarBotonesFooter = true;
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }
}
