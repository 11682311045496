import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubGastosService {

  public apiUrl:string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public subGastos(descripcion:string):Observable<any>{
    return this.http.get(`${this.apiUrl}SubGastos`);
  }

  public guardarGasto(SubGasto:any):Observable<any>{
    if (SubGasto.SubGasto_Id == 0) {
      return this.http.post<any>(`${this.apiUrl}SubGastos`, SubGasto)
    } else {
      return this.http.put(`${this.apiUrl}SubGastos/${SubGasto.SubGasto_Id}`, SubGasto);
    }
  }
}
