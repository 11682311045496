import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GrupoService } from '../../servicios/grupo.service';
import { UsuarioService } from '../../servicios/UsuarioService';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-agregar-usuarios-grupos',
  templateUrl: './agregar-usuarios-grupos.component.html',
  styleUrls: ['./agregar-usuarios-grupos.component.css']
})
export class AgregarUsuariosGruposComponent implements OnInit {

  grupo : any ;
  grupos: any = [];
  usuarios: any = [];
  usuariosAsig: any = [];
  usuariosDisp : any = [];
  
  mensajeModal: string ;
  resultado : any;
  IdUsuarioAgregar: string ="";
  IdUsuarioRemover : string = "";
  
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  constructor(private _grupoService: GrupoService,
    private _usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.grupo = {
      IdGrupo : "",
      Descripcion : "",
      Estatus : true
    }
  }

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.grupos = [];
    this.ventanaBusqueda.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
     if (this.usuarios.length == 0){
       this.consultarUsuarios();
    }
  }

  public consultarUsuarios(){
    this._usuarioService.obtenerUsuarios().subscribe(
      data => {
        this.usuarios = data.result;
        console.log(this.usuarios);
      }
    );
  }

  public buscarGrupos(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this._grupoService.obtenerGrupoByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.grupos = data.result;
        if (this.grupos.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItem(IdGrupo){
    this.grupo = this.grupos.find(item => item.IdGrupo == IdGrupo);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
    this.obtenerUsuariosAsignados(this.grupo.IdGrupo);
  }

  public obtenerUsuariosAsignados(IdGrupo){
    this._grupoService.obtenerUsuariosPorGrupo(IdGrupo).subscribe(
      data => {
       console.log(data.result);
          this.usuariosAsig = data.result;
          this.usuariosDisp = [];
          this.usuarios.forEach(element => {
              let item = this.usuariosAsig.find(item => item.IdUsuario == element.IdUsuario);
              if (item == null){
                this.usuariosDisp.push(element);
              }
          }); 
        }
    );
  }

  public cancelar(){
    this.grupo = {
      IdGrupo : "",
      Descripcion : "",
      Estatus : true
    }
    this.mostrarBotonesFooter = false;
    this.usuariosAsig = this.usuariosDisp = [];
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public guardar(){ 
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
    this._grupoService.guardarUsuariosAGrupo(this.grupo.IdGrupo, this.usuariosAsig).subscribe(
      data=>{
        console.log(data);
        if (data.msg != ""){
          this.lModalExito = data.msg.indexOf("correctamente") >= 0;
          this.asignarEstiloModal();
          this.mensajeModal = data.msg;
          this.largeModal.show();
          this.cancelar();
        }
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.mostrarBotonesFooter = false;
      });
  }

  public nuevo(){
    this.grupo = {
      IdGrupo : "",
      Descripcion : "",
      Estatus : true
    }
    this.mostrarBotonesFooter = true;
  }

  public removerItem(){
    if (this.IdUsuarioRemover !== ""){
      let item = this.usuariosAsig.find(item => item.IdUsuario == this.IdUsuarioRemover);
      let index = this.usuariosAsig.indexOf(item);
      this.usuariosAsig.splice(index,1);
      this.usuariosDisp.push(item);
      this.IdUsuarioRemover = "";
    }
  }

  public removerItems(){
    this.IdUsuarioAgregar = this.IdUsuarioRemover = "";
    this.usuariosDisp = this.usuariosDisp.concat(this.usuariosAsig);
    this.usuariosAsig = [];
  }

  public agregarItem(){
    if (this.IdUsuarioAgregar !== ""){
      let item = this.usuariosDisp.find(item => item.IdUsuario == this.IdUsuarioAgregar);
      let index = this.usuariosDisp.indexOf(item);
      this.usuariosDisp.splice(index,1);
      this.usuariosAsig.push(item);
      this.IdUsuarioAgregar = "";
    }
  }

  public agregarItems(){
    this.IdUsuarioAgregar = this.IdUsuarioRemover = "";
    this.usuariosAsig = this.usuariosAsig.concat(this.usuariosDisp);
    this.usuariosDisp = [];
  }

  public seleccionarItemAgregar(event){
    this.IdUsuarioAgregar = event.target.value ;
    console.log(this.IdUsuarioAgregar);
  }

  public seleccionarItemRemover(event){
    this.IdUsuarioRemover = event.target.value ;
    console.log(this.IdUsuarioRemover);
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success")
      else
      jQuery("#ventanaModal").addClass("modal-danger")

  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }
}
