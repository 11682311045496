import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { UsuarioService } from '../../servicios/UsuarioService';
import { EmpresaService } from '../../servicios/empresa.service';
import { SucursalService } from '../../servicios/sucursal.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{

  public usuario : any;
  public usuarioEnSesion : string;
  public esCatalogo = false;

  public empresas: any = [];
  public sucursales: any = [];

  constructor(private _userService: UsuarioService,
      private router: Router,
      private empresaService: EmpresaService,
      private sucursalService: SucursalService){

  }

  public ngOnInit(){
   this.usuario =  this._userService.getUserLogged();
   if (this.usuario != null){
     this.usuarioEnSesion = this.usuario.Login;
   }
   this.empresaService.obtenerEmpresas().subscribe(
     data=>{
       this.empresas = data.result;
       this.empresas.unshift({"IdEmpresa": 0, "NombreComercial": "Seleccione"})
       console.log(this.empresas);
       this.sucursales = [{"IdSucursal": 0, "Descripcion": "Seleccione"}];
     },
     error => console.log(error)
   );
  }

  public obtenerSucursales(event){
      if (event.target.value === "0"){
        this.sucursales = [{"IdSucursal": 0, "Descripcion": "Seleccione"}];
      }
      else{
        this.sucursalService.obtenerSucursalByIdEmpresa(event.target.value)
          .subscribe(data => {
            this.sucursales = data.result;
            this.sucursales.unshift( {"IdSucursal": 0, "Descripcion": "Seleccione"});
          },
          error=> console.log(error)
          );
      }
  }

  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    //this.sidebarMinimized = e;
    this.sidebarMinimized = false;
    this.abrirMenu();
    
  }
  public abrirMenu(){
    this.router.navigate(['Inicio/Menu']);
  }
  public cerrarSesion(){
    this._userService.logOut();
  }

  public menu (){
    this.router.navigate(['Inicio/Menu']);
  }

  public mostrarOpcionesCatalogo(event){
    console.log(event);
    if (event.catalogo != undefined && event.catalogo != null &&
      event.catalogo ){
        this.esCatalogo  = true;
      }
  }
}
