import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmpresaService } from '../../servicios/empresa.service';
import { SucursalService } from '../../servicios/sucursal.service';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.css']
})
export class SucursalesComponent implements OnInit {
  sucursal:any = {};
  sucursales: any = [];
  empresa: any = {};
  empresas: any = [];

  busqueda: string  = "";
  mensajeModal: string ;
  resultado : any;
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;

  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje: string = "";

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusquedaSucursales', {static: false}) 
  public ventanaBusquedaSucursales: ModalDirective;

  @ViewChild('ventanaBusquedaEmpresas', {static: false}) 
  public ventanaBusquedaEmpresas: ModalDirective;

  constructor(
    private sucursalService: SucursalService,
    private empresaService: EmpresaService) { }

  ngOnInit(): void {
    this.sucursal = {
    IdSucursal : "",
    Descripcion : "",
    IdEmpresa : ""
    };
    this.empresa = {
      IdEmpresa : "",
      RazonSocial : "",
      NombreComercial : ""
    };

    this.empresaService.obtenerEmpresas().subscribe(
      data=>{
        this.empresas = data.result;
      }
    );
  }

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.sucursales = [];
    this.ventanaBusquedaSucursales.show();
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public consultar(textoBusqueda){
    this.sucursalService.obtenerSucursalById(textoBusqueda).subscribe(
      data => {
        let resultado = data.result;
        
        if (resultado != null){
          this.sucursal = resultado;
          this.mostrarBotonesFooter = true;
        }
        else{
          this.mensajeModal = "No se encontró un grupo con el id proporcionado";
          this.largeModal.show();
          this.cancelar();
        }
      }
    );
  }

  public cancelar(){
    this.sucursal.IdSucursal = "";
    this.sucursal.Descripcion = "";
    this.sucursal.IdEmpresa = "";
    this.empresa.IdEmpresa = "";
    this.empresa.NombreComercial = "";
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public guardar(){
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    var mensaje:string ="";   
    if (this.sucursal.IdSucursal === ""){
      mensaje = "El campo Id es requerido";
    }
    else if (this.sucursal.IdEmpresa == ""){
      mensaje = "El campo Empresa es requerido";
    }
    else if (this.sucursal.Descripcion == ""){
      mensaje = "El campo Descripción es requerido";
    }
    if (mensaje != ""){
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.mensajeModal = mensaje;
      this.largeModal.show();
      return;
    }
    this.mostrarBotonesFooter = false;
    this.sucursalService.guardarRegistro(this.sucursal).subscribe(
      data=>{
               console.log(data);
               let resultado = data.result;
               if (data.msg != ""){
                this.lModalExito = data.msg.indexOf("correctamente") >= 0;
                this.asignarEstiloModal();
                this.mensajeModal = data.msg;
                this.largeModal.show();
                this.cancelar();
              }
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.mostrarBotonesFooter = false;
      });

  }

  public nuevo(){
    this.sucursal.IdSucursal = 0;
    this.sucursal.Descripcion = "";
    this.sucursal.IdEmpresa = "";
    this.empresa.IdEmpresa = "";
    this.empresa.NombreComercial ="";
    this.mostrarBotonesFooter = true;
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public catalogoEmpresas(){
      this.textoVentanaBusqueda = "";
      this.empresas = [];
      this.ventanaBusquedaEmpresas.show();
      if (this.menuAccionesVisible){
        jQuery("#btnOpciones").click();
        this.menuAccionesVisible = false;
      }
  }

  public buscarEmpresas(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
    this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.empresaService.obtenerEmpresasByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.empresas = data.result;
        if (this.empresas.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItem(IdEmpresa){
    this.empresa = this.empresas.find(item => item.IdEmpresa == IdEmpresa);
    this.sucursal.IdEmpresa = this.empresa.IdEmpresa;
    this.ventanaBusquedaEmpresas.hide();
  }

  public buscarSucursales(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistrosSucursales(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistrosSucursales(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.sucursalService.obtenerSucursalesByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.sucursales = data.result;
        if (this.sucursales.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItemSucursal(IdSucursal){
    this.sucursal = this.sucursales.find(item => item.IdSucursal == IdSucursal);
    this.empresa.IdEmpresa = this.sucursal.IdEmpresa;
    this.empresa.NombreComercial = this.sucursal.Empresa.NombreComercial;
    this.ventanaBusquedaSucursales.hide();
    this.mostrarBotonesFooter = true;
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }
 }

