import { LoaderInterceptorService } from './interceptors/loader-interceptor.service';
import { LoaderService } from './servicios/loader.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BotonComponent } from './componentes/boton/boton.component';
import { DataTablesModule } from "angular-datatables";
// Modal Component
import { ModalModule } from 'ngx-bootstrap/modal';
import { OpcionesMenuComponent } from './componentes/opciones-menu/opciones-menu.component';
import { LoginComponent } from './componentes/login/login.component';
// Componentes Control de Acceso
import { UsuariosComponent } from './componentes/usuarios/usuarios.component';
import { EmpleadosComponent } from './componentes/empleados/empleados.component';
import { GruposComponent } from './componentes/grupos/grupos.component';
import { EmpresasComponent } from './componentes/empresas/empresas.component';
import { SucursalesComponent } from './componentes/sucursales/sucursales.component';
// Componentes Catalogos
import { AgregarPaginasGruposComponent } from './componentes/agregar-paginas-grupos/agregar-paginas-grupos.component';
import { AgregarUsuariosGruposComponent } from './componentes/agregar-usuarios-grupos/agregar-usuarios-grupos.component';
import { CambiarPasswordComponent } from './componentes/cambiar-password/cambiar-password.component';
import { AgregarSucursalesUsuariosComponent } from './componentes/agregar-sucursales-usuarios/agregar-sucursales-usuarios.component';
// Componentes Catalogos para el Presupuesto
import { GastosComponent } from './componentes/gastos/gastos.component';
import { SubGastosComponent } from './componentes/sub-gastos/sub-gastos.component';
import { GastosAAreasComponent } from './componentes/gastos-a-areas/gastos-a-areas.component';
import { SubGastosAGastosComponent } from './componentes/sub-gastos-a-gastos/sub-gastos-a-gastos.component';
import { PresupuestoAdministrativoComponent } from './componentes/presupuesto-administrativo/presupuesto-administrativo.component';
import { BtnCapturaGastoComponent } from './componentes/btn-captura-gasto/btn-captura-gasto.component';
import { SpinnerLoaderComponent } from './componentes/spinner-loader/spinner-loader.component';
import { ClientesComponent } from './componentes/clientes/clientes.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    FormsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    PopoverModule.forRoot(),
    DataTablesModule
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    GruposComponent,
    UsuariosComponent,
    LoginComponent,
    EmpleadosComponent,
    OpcionesMenuComponent,
    AgregarPaginasGruposComponent,
    AgregarUsuariosGruposComponent,
    CambiarPasswordComponent,
    BotonComponent,
    EmpresasComponent,
    SucursalesComponent,
    AgregarSucursalesUsuariosComponent,
    GastosComponent,
    SubGastosComponent,
    GastosAAreasComponent,
    SubGastosAGastosComponent,
    PresupuestoAdministrativoComponent,
    BtnCapturaGastoComponent,
    SpinnerLoaderComponent,
    ClientesComponent
  ],
  providers: [
    LoaderService,
    /*{
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    }*/
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
