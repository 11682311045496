import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  public baseUrl:string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  public obtenerEmpresaById(idEmpresa: any):Observable<any>{
      return this._http.get(`${this.baseUrl}Empresas/selectById?idEmpresa=${idEmpresa}`);
  }

  public guardarRegistro (Empresa : any):Observable<any>{
   return this._http.post<any>(`${this.baseUrl}Empresas/guardar`,Empresa);
  }

  public obtenerEmpresas(): Observable<any>{
    return this._http.get<any>(`${this.baseUrl}Empresas/selectAll`);
  }

  public obtenerEmpresasByDescripcion(textoBusqueda: string ): Observable<any>{
    return this._http.get(`${this.baseUrl}Empresas/selectByDescripcion?Descripcion=${textoBusqueda}`);
  }
}
