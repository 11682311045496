import { Presupuesto } from './../interfaces/presupuesto';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {

  public apiUrl:string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getPresupuesto(Area_Id: number, Anio: number, Mes_Id: number) : Observable<any> {
    var params = `Area_Id=${Area_Id}&Anio=${Anio}&Mes_Id=${Mes_Id}`;
    return this.http.get(`${this.apiUrl}Presupuesto/getPresupuesto?${params}`);
  }

  public guardarCapturaPresupuesto(Presupuesto:any) : Observable<any>{
    if (Presupuesto.Presupuesto_Administrativo_Id == 0) {
      // Es Nuevo
      return this.http.post<any>(`${this.apiUrl}Presupuestos`, Presupuesto)
    } else {
      // Es UPDATE
      return this.http.put(`${this.apiUrl}Presupuestos/${Presupuesto.Presupuesto_Administrativo_Id}`, JSON.stringify(Presupuesto));
    }
  }

}
