import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  public baseUrl:string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  public obtenerGrupoById(idGrupo: any):Observable<any>{
      return this._http.get(`${this.baseUrl}Grupos/selectById?idGrupo=${idGrupo}`);
  }

  public guardarRegistro (Grupo : any):Observable<any>{
   return this._http.post<any>(`${this.baseUrl}Grupos/guardar`,Grupo);
  }

  public obtenerGrupoByDescripcion(textoBusqueda: string ): Observable<any>{
    return this._http.get(`${this.baseUrl}Grupos/selectByDescripcion?Descripcion=${textoBusqueda}`);
  }

  public obtenerPaginasPorGrupo(IdGrupo: number ): Observable<any>{
    return this._http.get(`${this.baseUrl}Grupos/selectPaginasPorGrupo?IdGrupo=${IdGrupo}`);
  }

  public obtenerUsuariosPorGrupo(IdGrupo: number ): Observable<any>{
    return this._http.get(`${this.baseUrl}Grupos/selectUsuariosPorGrupo?IdGrupo=${IdGrupo}`);
  }
  
  public guardarPaginasAGrupo(IdGrupo: number, Paginas:any): Observable<any>{
    let valores = {IdGrupo, Paginas};
    return this._http.post<any>(`${this.baseUrl}Grupos/guardarPaginasAGrupo`, valores);
  }

  public guardarUsuariosAGrupo(IdGrupo: number, Usuarios:any): Observable<any>{
    let valores = {IdGrupo, Usuarios};
    return this._http.post<any>(`${this.baseUrl}Grupos/guardarUsuariosAGrupo`, valores);
  }
}
