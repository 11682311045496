import { SubGastosService } from './../../servicios/subgastos.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sub-gastos',
  templateUrl: './sub-gastos.component.html',
  styleUrls: ['./sub-gastos.component.css']
})
export class SubGastosComponent implements OnInit {

  // Modales
  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  mensajeModal: string ;
  busqueda: string;
  resultado : any;
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;

  subGasto: any;
  subGastos: any;

  constructor(private subGastosService: SubGastosService) { }

  ngOnInit(): void {
    this.subGasto = {
      SubGasto_Id: "",
      Descripcion: "",
      Presupuestable: 0,
      Estatus: 0
    };

    jQuery("#btnOpciones").on("click", function(){
      return true;
    })
  }


  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.subGastos = [];
    this.ventanaBusqueda.show();
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public consultarRegistros() {
    this.resultadoBusquedaMensaje = "";
    this.subGastosService.subGastos(this.textoVentanaBusqueda).subscribe(
      data => {
        this.subGastos = data;
        if (this.subGastos.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    )
  }

  public seleccionarItem(SubGasto_Id) {
    this.subGasto = this.subGastos.find(item => item.SubGasto_Id == SubGasto_Id);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
  }

  public cancelar() {
    this.subGasto = {
      SubGasto_Id: "",
      Descripcion: "",
      Presupuestable: 0,
      Estatus: 0
    }
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public guardar() {
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    this.mensajeModal = "";
    if (this.subGasto.Descripcion == "") {
      this.mensajeModal = "El campo Nombre es requerido";
    }
    if (this.mensajeModal != ""){
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.largeModal.show();
      return;
    }

    this.mostrarBotonesFooter = false;
    this.subGastosService.guardarGasto(this.subGasto).subscribe(
      data => {
        this.resultado = data.result;
        this.lModalExito = data.msg == "";
        this.mensajeModal = data.msg == "" ? "Registro Guardado con Éxito" : data.msg;
        this.asignarEstiloModal();
        this.largeModal.show();
        if (this.resultado != 0) {
          this.subGasto = this.resultado;
        }
      },
      error => {
        this.mensajeModal = error;
        this.largeModal.show();
      },
      () => {
        this.mostrarBotonesFooter = false;
        this.cancelar();
      }
    );
  }

  public nuevo() {
    this.subGasto = {
      SubGasto_Id: 0,
      Descripcion: "",
      Presupuestable: 0,
      Estatus: 0
    }
    jQuery("#estatus").prop("checked", true);
    jQuery("#presupuestable").prop("checked", true);
    this.mostrarBotonesFooter = true;
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public asignarEstiloModal() {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public iniciarTimer() {
    this.menuAccionesVisible = true;
  }

  public ocultarMenu() {
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

}
