export class Mes {
    public Mes_Id: number;
    public Nombre: string;

    constructor(noMes: number) {
        this.Mes_Id = noMes;
        var mes: any;
        switch (noMes) {
            case 1: 
                this.Nombre = "Enero"; break;
            case 2: 
                this.Nombre = "Febrero"; break;
            case 3: 
                this.Nombre = "Marzo"; break;
            case 4: 
                this.Nombre = "Abril"; break;
            case 5: 
                this.Nombre = "Mayo"; break;
            case 6: 
                this.Nombre = "Junio"; break;
            case 7: 
                this.Nombre = "Julio"; break;
            case 8: 
                this.Nombre = "Agosto"; break;
            case 9: 
                this.Nombre = "Septiembre"; break;
            case 10: 
                this.Nombre = "Octubre"; break;
            case 11: 
                this.Nombre = "Noviembre"; break;
            case 12: 
                this.Nombre = "Diciembre"; break;
        }
    }

    getNoMesFromNombre(mes: string) {
        switch (mes) {
            case "Enero": return 1;
            case "Febrero": return 2;
            case "Marzo": return 3;
            case "Abril": return 4;
            case "Mayo": return 5;
            case "Junio": return 6;
            case "Julio": return 7;
            case "Agosto": return 8;
            case "Septiembre": return 9;
            case "Octubre": return 10;
            case "Noviembre": return 11;
            case "Diciembre": return 12;
        }
    }
    
}