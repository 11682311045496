import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  public apiUrl:string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public areas(descripcion: string) : Observable<any> {
    return this.http.get(`${this.apiUrl}Areas`);
  }

  public areaConGastos(Area_Id: number) : Observable<any> {
    return this.http.get(`${this.apiUrl}getAreaWithGastos?Area_Id=${Area_Id}`);
  }

  public guardarArea(Area: any) : Observable<any> {
    return this.http.put(`${this.apiUrl}Areas/${Area.Area_Id}`, JSON.stringify(Area));
  }

}
