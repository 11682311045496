import { Mes } from './../modelos/meses';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }

  exportPlantillaPresupuestoToExcel(excelData) {
    // Headers
    const titulo = "Plantilla";
    const encabezado = "Plantilla de Carga para el Presupuesto";
    const data = excelData.data;

    // Generamos el Workbook con una worksheet
    let workBook = new Workbook();
    let workSheet = workBook.addWorksheet('Presupuesto');

    // Generamos el encabezado del Presupuesto
    workSheet.mergeCells('A2', 'F2');
    let titleRow = workSheet.getCell('A2');
    titleRow.value = encabezado;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '000000' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Datos de la plantilla del presupuesto
    let cAreaText = workSheet.getCell('A4');
    let cAreaId = workSheet.getCell('B4');
    let cAreaName = workSheet.getCell('C4');
    let cAnioText = workSheet.getCell('A5');
    let cAnioValue = workSheet.getCell('B5');
    let cMesText = workSheet.getCell('A6');
    let cMesValue = workSheet.getCell('B6');
    let cCostoText = workSheet.getCell('A7');
    let cCostoValue = workSheet.getCell('B7');
    let cCostoAdicionalText = workSheet.getCell('A8');
    let cCostoAdicionalValue = workSheet.getCell('B8');

    cAreaText.value = "Area ID";
    cAreaId.value = data.Area_Id;
    cAreaName.value = data.area.Descripcion;
    cAnioText.value = "Año";
    cAnioValue.value = data.Anio;
    cMesText.value = "Mes";
    cMesValue.value = new Mes(data.Mes_Id).Nombre;
    cCostoText.value = "Costo Total";
    cCostoValue.value = data.Costo_Total;
    cCostoAdicionalText.value = "Costo Adicional";
    cCostoAdicionalValue.value = 0;

    // Titulo de la tabla de captura
    let cHeadGastoId = workSheet.getCell('A10');
    let cHeadGastoValue = workSheet.getCell('B10');
    let cHeadSubGastoId = workSheet.getCell('C10');
    let cHeadSubGastoValue = workSheet.getCell('D10');
    let cHeadConcepto = workSheet.getCell('E10');
    let cHeadCosto = workSheet.getCell('F10');

    cHeadGastoId.value = "Gasto Id";
    cHeadGastoValue.value = "Nombre Gasto";
    cHeadSubGastoId.value = "Sub Gasto Id";
    cHeadSubGastoValue.value = "Nombre Sub Gasto";
    cHeadConcepto.value = "Concepto";
    cHeadCosto.value = "Costo";

    // Se llena la informacion de la tablas
    let cDataGastoId = null;
    let cDataGastoValue = null;
    let cDataSubGastoId = null;
    let cDataSubGastoValue = null;
    let cDataConcepto = null;
    let cDataCosto = null;
    let row = 11;
    let huboSubGastos = false;

    // Iteramos los detalles (Gastos)
    data.detalles_presupuesto.forEach(eDetalle => {
      cDataGastoId = workSheet.getCell(`A${row}`);
      cDataGastoValue = workSheet.getCell(`B${row}`);
      cDataGastoId.value = eDetalle.gasto.Gasto_Id;
      cDataGastoValue.value = eDetalle.gasto.Descripcion

      // Iteramos los Sub gastos por Gastos
      eDetalle.detalles_gasto.forEach(eSubGasto => {
        cDataSubGastoId = workSheet.getCell(`C${row}`);
        cDataSubGastoValue = workSheet.getCell(`D${row}`);
        cDataConcepto = workSheet.getCell(`E${row}`);
        cDataCosto = workSheet.getCell(`F${row}`);
        cDataSubGastoId.value = eSubGasto.SubGasto_Id;
        cDataSubGastoValue.value = eSubGasto.Descripcion;
        cDataConcepto.value = ""; // eSubGasto.Concepto;
        cDataCosto.value = 0;     // eSubGasto.Costo;
        row++;
        huboSubGastos = true;
      });

      if (!huboSubGastos) {
        row++;
      }
      huboSubGastos = false;
    });

    // Guardamos el Excel
    workBook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, titulo + '.xlsx');
    })
  }
}
