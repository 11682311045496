import { ExportExcelService } from './../../servicios/export-excel.service';
import { LanguageApp } from './../../modelos/language.app';
import { AreasService } from './../../servicios/areas.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GastosService } from './../../servicios/gastos.service';
import { IComponentEventType } from './../../interfaces/component-event-type';
import { BtnCapturaGastoComponent } from './../btn-captura-gasto/btn-captura-gasto.component';
import { Subject } from 'rxjs';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Mes } from '../../modelos/meses';
import { PresupuestoService } from '../../servicios/presupuesto.service';
import { DataTableDirective } from 'angular-datatables';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-presupuesto-administrativo',
  templateUrl: './presupuesto-administrativo.component.html',
  styleUrls: ['./presupuesto-administrativo.component.css']
})
export class PresupuestoAdministrativoComponent implements OnInit, AfterViewInit, OnDestroy {

  // dtOptions: ADTSettings  = {}
  // dtTrigger: Subject<ADTSettings> =  new Subject<ADTSettings>();
  dtOptionsGastos: DataTables.Settings = {};
  dtOptionsCaptura: DataTables.Settings = {};
  dtTriggerGastos: Subject<any> =  new Subject<any>();
  dtTriggerCaptura: Subject<any> =  new Subject<any>();

  //@ViewChild(DataTableDirective, {static: false})
  //dtElement: DataTableDirective;

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  @ViewChild('btnCapturaGastoNg') btnCapturaGastoNg: TemplateRef<BtnCapturaGastoComponent>;
  message = '';

  // Entities
  area: any = {};
  areas: any = [];
  gasto: any = {};
  gastos: any = [];
  subGasto: any = {};
  subGastos: any = [];
  anios: any = [];
  meses: any = [];
  // presupuesto seleccionad (Area, Anio  y Mes)
  presupuesto : any;
  // detalle del presupuesto (es el gasto seleccionado)
  detallePresupuestoSeleccionado: any;
  // detalle del gasto seleccionado (es el subgasto)
  detallePresupuestoSubGastoSeleccionado: any;
  subGastosPorGastos: any;

  // seleccions
  anioSelected: any;
  mesSelected: any;
  subGastoSelected: any;
  ocultarPrincipal:boolean;
  ocultarGastos: boolean;
  ocultarCaptura: boolean;
  editandoDetalleGasto: boolean;
  detalleGastoSelected: any;

  // Modales
  @ViewChild('largeModal', {static: false}) public largeModal: ModalDirective;
  @ViewChild('ventanaBusqueda', {static: false}) public ventanaBusqueda: ModalDirective;
  @ViewChild('ventanaUpload', {static: false}) public ventanaUpload: ModalDirective;

  mensajeModal: string ;
  resultado : any;
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  menuAccionesVisible : boolean=  false;

  constructor(
    private gastosService: GastosService,
    private areasService: AreasService,
    private presupuestoService: PresupuestoService,
    private exportXLS: ExportExcelService
    ) { }

  ngOnInit(): void {
    this.inicializarEntidades();

    // Configuracion tabla de Gastos del Presupuesto
    this.dtOptionsGastos = {
      processing: true,
      responsive: true,
      language: LanguageApp.spanish_datatables,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.clickHandler_Gastos(data);
        });
        return row;
      }
    }
    // Configuracion tabla para la captura de Sub Gastos / Conceptos
    this.dtOptionsCaptura = {
      processing: true,
      responsive: true,
      language: LanguageApp.spanish_datatables,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.clickHandler_Captura(data);
        });
        return row;
      }
    }

    // this.dtTrigger.next(this.dtOptions);
    // this.actividadActual = "principal"
    this.mostrarPanelPrincipal();
  }

  private inicializarEntidades(){
    // Inicializamos los Entities
    this.gasto = {
      Gasto_Id: "",
      Descripcion: "",
      Estatus: 0
    }
    this.area = {
      Area_Id: "",
      Descripcion: "",
      Estatus: 0
    }
    this.presupuesto = {
      Presupuesto_Administrativo_Id: "",
      Anio: "",
      Mes_Id: "",
      Costo_Total: 0.0,
      estatus: {
        Descripcion: ''
      },
      detalles_presupuesto: []
    }
    this.detallePresupuestoSeleccionado = {
      Observaciones: ""
    };
    this.detallePresupuestoSubGastoSeleccionado = {
      Concepto: "",
      Costo: 0.0
    }

    this.subGastosPorGastos = []
    this.anioSelected = null;
    this.mesSelected = null;
    this.editandoDetalleGasto = false;
    this.detalleGastoSelected = null;
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dtTriggerGastos.next(this.dtOptionsGastos);
      this.dtTriggerCaptura.next(this.dtOptionsCaptura);
    }, 200);
  }

  onCaptureEvent(event: IComponentEventType) {
    this.message = `Event '${event.cmd}' with data '${JSON.stringify(event.data)}`;
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerGastos.unsubscribe();
    this.dtTriggerCaptura.unsubscribe();
  }

  rerender(tableId: string, reset: boolean): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      console.log("Iterando cada tbla >> ", dtElement.dtInstance, dtElement);
      dtElement.dtInstance.then((dtInstance: any) => {
        if (tableId == dtInstance.table().node().id && reset ) {
          dtInstance.clear().draw();
        }
        if (dtInstance.table().node().id == "dtGastos") {
          console.log(`The DataTable to RENDER ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.destroy();
          this.dtTriggerGastos.next();
        } else if (dtInstance.table().node().id == "dtcaptura") {
          console.log(`The DataTable to RENDER ${index} instance ID is: ${dtInstance.table().node().id}`);
          dtInstance.destroy();
          this.dtTriggerCaptura.next();
        }
      })
    });
  }

  iniciarTimer() {
    this.menuAccionesVisible = true;
  }

  nuevo() {
    this.inicializarEntidades();
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  guardar() {
    jQuery("#btnOpciones").click();
    console.log("Informacion Enviada >>> ", this.presupuesto);
    this.presupuestoService.guardarCapturaPresupuesto(this.presupuesto).subscribe(
      data => {
        console.log("Guardado Ejecutado >>> ", data);
        this.lModalExito = data.msg == "";
        this.mensajeModal = data.msg == "" ? "Registro Guardado con Éxito" : data.msg;
        this.asignarEstiloModal();
        this.largeModal.show();
        if (!this.lModalExito) this.cancelar();
      },
      error => {
        console.log("Error al guardar >> ", error);
      },
      () => {
        this.cancelar();
      });
  }

  cancelar() {
    this.nuevo();
  }

    // Asigna el estido del modal de acuerdo a la respuesta de los servicios
    public asignarEstiloModal() {
      jQuery("#ventanaModal").removeClass("modal-success");
      jQuery("#ventanaModal").removeClass("modal-danger");
      if (this.lModalExito)
        jQuery("#ventanaModal").addClass("modal-success")
        else
        jQuery("#ventanaModal").addClass("modal-danger")
  
    }

  // ------------------------------------------------------------------------------------------------------------------
  // Metodos para el manejo y Seleccion del Área
  public realizarBusqueda() {
    this.inicializarEntidades();
    this.rerender("dtGastos", true);
    this.textoVentanaBusqueda = "";
    this.areas = [];
    this.ventanaBusqueda.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  // Consulta las areas de acuerdo al filtro seleccionado
  public buscarAreas(){
    this.resultadoBusquedaMensaje = "";
    this.areasService.areas(this.textoVentanaBusqueda).subscribe(
      data => {
        this.areas = data;
        if (this.areas.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    )
  }

  // Se selecciona un item del resultado de la busqueda
  public seleccionarItem(Area_Id){
    this.area = this.areas.find(item => item.Area_Id == Area_Id);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
    this.obtenerAnios();
    this.anioSelected = " Selecciona";
    this.mostrarPanelPrincipal();
  }

  // Genera el Data para el manejo de los Anios
  public obtenerAnios(){
    console.log("obtener anios ");
    var d = new Date();
    this.anios = [];
    this.anios.push(d.getFullYear());
    this.anios.push(d.getFullYear() + 1);
    this.anioSelected = "Selecciona";
  }

  public obtenerMeses(anio: any) {
    console.log("obtener Meses del anio > ", anio);
    this.meses = [];
    var d = new Date();
    for(var iMes = 1; iMes <= 12; iMes++) {
      this.meses.push(new Mes(iMes));
    }
    this.mesSelected = "Selecciona";
  }

  // ------------------------------------------------------------------------------------------------------------------
  // Se consultan si existe un presupuesto Asignado
  public obtenerPresupuesto(mes: any) {
    console.log("Obtenemos el Presupuesto de acuerdo a los parametros!!");

    this.presupuestoService.getPresupuesto(this.area.Area_Id, this.anioSelected, this.mesSelected).subscribe(
      data => {
        if (data.msg == "") {
          this.presupuesto = data.result;
          console.log("Resultado de la consulta > ", this.presupuesto);
          // Se muestran los datos del Resumen de Gastos
          this.rerender("dtGastos", true);
        } else {
          // TODO  Hubo un error
        }
      });
  }

  clickHandler_Gastos(info: any): void {
  }

  clickHandler_Captura(info: any): void {
  }

  // ------------------------------------------------------------------------------------------------------------------
  // Muestra la seccion de pantalla para el detalle de los gastos
  public verDetalleGastos(Gasto_Id, reset: boolean){
    this.mostrarPanelGastos();
    this.detallePresupuestoSeleccionado = {};
    this.subGastosPorGastos = [];
    this.detallePresupuestoSeleccionado = this.presupuesto.detalles_presupuesto.find(item => item.Gasto_Id == Gasto_Id);
    this.subGastosPorGastos = this.detallePresupuestoSeleccionado.detalles_gasto;
    console.log(" sub gastos before > ", this.subGastosPorGastos);
    this.rerender("dtcaptura", false);
    console.log(" sub gastos after > ", this.subGastosPorGastos);

  }

  // ------------------------------------------------------------------------------------------------------------------
  // Metodo para Agregar un Sub Gasto al Gasto
  agregarSubGasto() {
    console.log(" mostrando panel de captura > ", this.detallePresupuestoSeleccionado);
    this.editandoDetalleGasto = false;
    this.subGastoSelected = "Seleccione";
    this.gastosService.gastoConSubGastos(this.detallePresupuestoSeleccionado.Gasto_Id).subscribe(
      data => {
        if (data.msg == "") {
          this.subGastos = data.result.sub_gastos;
          this.detallePresupuestoSubGastoSeleccionado = {
						Presupuesto_Administrativo_Detalle_Id: 0,
						SubGasto_Id: 0,
						Concepto: "",
						Costo: 0.0,
						subGasto: {}
          }
          this.mostrarPanelCaptura();
        }
      });

  }

  editarDetalleGastos(Concepto, Descripcion) {
    console.log("try catch edit params >>>> ", Concepto, Descripcion);
    let SubGasto_Id = 0;
    this.editandoDetalleGasto = true;
    this.detallePresupuestoSubGastoSeleccionado = this.subGastosPorGastos.find(item => item.subGasto.Descripcion == Descripcion && item.Concepto == Concepto);
    this.subGastos = [{ 
      SubGasto_Id: this.detallePresupuestoSubGastoSeleccionado.subGasto.SubGasto_Id,
      Descripcion: this.detallePresupuestoSubGastoSeleccionado.subGasto.Descripcion,
    }]
    this.subGastoSelected = this.detallePresupuestoSubGastoSeleccionado.subGasto.SubGasto_Id;
    console.log(" LOG editando >>>> ", this.detallePresupuestoSubGastoSeleccionado);
    
    this.mostrarPanelCaptura();
  }

  // Regresa a la pantalla Principal de captura del presupuesto
  regresarPrincipal(){
    this.mostrarPanelPrincipal();
    this.detallePresupuestoSeleccionado = {};
    this.subGastosPorGastos = [];
    this.rerender("dtGastos", false);
    console.log("Regresando a la pantalla principal > ", this.presupuesto);
  }

  subGastoChange(fieldName: string, fieldValue: string) {
    console.log(" ChaNGE SELECT >>> ", fieldName, fieldValue);
    this.subGasto = this.subGastos.find(item => item.SubGasto_Id == this.subGastoSelected);
    console.log("Sub gasto seleccionado >> ", this.detallePresupuestoSubGastoSeleccionado.SubGasto_Id, this.subGasto);
  }

  // Regresa a la pantalla con el detalle del Gasto
  aceptarGasto(){
    // Asignamos la informacion captura al detalle del Gasto
    if (this.editandoDetalleGasto) {
      this.editandoDetalleGasto = false;
    }else {
      this.subGasto = this.subGastos.find(item => item.SubGasto_Id == this.subGastoSelected);
      console.log("Sub gasto seleccionado >> ", this.subGastoSelected, this.subGasto);
      this.detallePresupuestoSubGastoSeleccionado.subGasto = this.subGasto;
      this.detallePresupuestoSubGastoSeleccionado.Presupuesto_Administrativo_Detalle_Id= this.presupuesto.detalles_presupuesto.Presupuesto_Administrativo_Detalle_Id;
      this.subGastosPorGastos.push(this.detallePresupuestoSubGastoSeleccionado);
      // Actualizamos hacia arriba la informacion
      this.detallePresupuestoSeleccionado.ImportePresupuesto += parseFloat(this.detallePresupuestoSubGastoSeleccionado.Costo);
      this.detallePresupuestoSeleccionado.PorcentajeDelPresupuesto = this.detallePresupuestoSeleccionado.ImportePresupuesto / parseFloat(this.presupuesto.Costo_Total);
      this.presupuesto.ImportePresupuesto += this.detallePresupuestoSubGastoSeleccionado.Costo;
      this.presupuesto.PorcentajeDelPresupuesto = this.presupuesto.ImportePresupuesto / parseFloat(this.presupuesto.Costo_Total);
    }
    // Mostramos la pantalla del gasto
    this.verDetalleGastos(this.detallePresupuestoSeleccionado.Gasto_Id, false);
  }

  cancelarGasto(){
    this.mostrarPanelGastos();
  }

  // ------------------------------------------------------------------------------------------------------------------
  // Metodo para mostrar/ocultar divs de la UI
  mostrarPanelPrincipal() {
    this.ocultarPrincipal = false;
    this.ocultarGastos = true;
    this.ocultarCaptura = true;
  }

  mostrarPanelGastos() {
    this.ocultarPrincipal = true;
    this.ocultarGastos = false;
    this.ocultarCaptura = true;
  }

  mostrarPanelCaptura() {
    this.ocultarPrincipal = true;
    this.ocultarGastos = true;
    this.ocultarCaptura = false;
  }

  // ------------------------------------------------------------------------------------------------------------------
  cargarPresupuesto() {
    this.ventanaUpload.show();
  }

  async descargarPlantilla() {
    // Obtenemos los Gastos que tiene el Area (nivel 1)
    let gastosPlantilla = [];
    let detallePlantilla = [];
    let subGastosPlantilla = [];

    console.log("validamos antes de descargar >>", this.anioSelected, this.mesSelected, this.area);
    if (this.anioSelected == null || this.mesSelected == null || this.area.Area_Id == "") {
        this.lModalExito = false;
        this.mensajeModal = "Debe seleccionar un Area, Año y Mes";
        this.asignarEstiloModal();
        this.largeModal.show();
        return;
    }

    this.areasService.areaConGastos(this.area.Area_Id).subscribe(
      data => {
        data.result.gastos.forEach(eGasto => {
          // Obtenemos los Sub Gastos por Gasto (nivel 2)
          subGastosPlantilla = [];
          eGasto.sub_gastos.forEach(eSubGasto => {
            console.log("Subg Gasto >> ", eSubGasto);
            subGastosPlantilla.push(eSubGasto);
          });
          detallePlantilla.push(
            {
              gasto: eGasto,
              detalles_gasto: subGastosPlantilla
            }
          );
        });

        // Como ya termnio mandamos a generar el excel
        let ppto = {
          Area_Id: this.area.Area_Id,
          area: this.area,
          Anio: this.anioSelected,
          Mes: this.mesSelected,
          Costo_Total: 0,
          Costo_Adicional: 0,
          detalles_presupuesto: detallePlantilla
        }

        let reportData = {
          data: ppto
        }

        this.exportXLS.exportPlantillaPresupuestoToExcel(reportData);

      });
  }

  readExcel(event) {
    const workbook = new Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('No se puede seleccionar multiples archivos');
    }

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    let self = this;
    arryBuffer.then(function (data) {
      workbook.xlsx.load(data)
        .then(function () {
          // Obtenemos la 1ra Hoja
          const worksheet = workbook.getWorksheet(1);
          console.log('rowCount: ', worksheet.rowCount);

          // Iteramos cada Fila para obtener los valores que necesitamos
          let presupuesto = {
            Presupuesto_Administrativo_Id: 0,
            Area_Id: 0,
            Anio: 0,
            Mes_Id: 0,
            Sucursal_Empresa_Id: 0,
            Usuario_Id_Registro: 0,
            Costo_Total: 0,
            Costo_Total_Adicional: 0,
            detalles_presupuesto: []
          }
          presupuesto.Area_Id = parseInt(worksheet.getRow(4).getCell("B").value.toString());
          presupuesto.Anio = parseInt(worksheet.getRow(5).getCell("B").value.toString());
          presupuesto.Mes_Id = new Mes(0).getNoMesFromNombre(worksheet.getRow(6).getCell("B").value.toString());
          presupuesto.Costo_Total = parseInt(worksheet.getRow(7).getCell("B").value.toString());
          presupuesto.Costo_Total_Adicional = parseInt(worksheet.getRow(8).getCell("B").value.toString());

          let GastoID = 0;
          let detalle = {
            Presupuesto_Administrativo_Detalle_Id: 0,
            Presupuesto_Administrativo_Id: 0,
            Gasto_Id: 0,
            Observaciones: "",
            detalles_gasto: []
          };

          // console.log("prepare >> ", presupuesto);
          worksheet.eachRow(function (row, rowNumber) {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log("Test Acces >>>> ", row.values[2], row.getCell("B").value);
            if (rowNumber >= 11) {
             // Validamos si existe la columna Gasto
             if (row.getCell("B").value != null) {
               // Existe un ID de Gasto
                detalle = {
                  Presupuesto_Administrativo_Detalle_Id: 0,
                  Presupuesto_Administrativo_Id: 0,
                  Gasto_Id: parseInt(row.getCell("A").value.toString()),
                  Observaciones: "",
                  detalles_gasto: []
                }
                presupuesto.detalles_presupuesto.push(detalle);
             } 
             
             // Validamos si existe sub gasto asignado
             if (
              row.getCell("C").value != null &&
              row.getCell("E").value != null &&
              row.getCell("F").value != null
              ) {
                // Si hay sub-gasto capturado, se asigna
                console.log("TEST VALUE >> ", row.getCell("C").value.toString());
                console.log("TEST VALUE >> ", row.getCell("E").value.toString());
                console.log("TEST VALUE >> ", row.getCell("F").value.toString());
                detalle.detalles_gasto.push( {
                  SubGasto_Id: parseInt(row.getCell("C").value.toString()),
                  Concepto: row.getCell("E").value.toString(),
                  Costo: parseInt(row.getCell("F").value.toString())
                });
              }
            }
          });

          console.log("Prespuesto para Guardar =====>>>> ", presupuesto);
          self.presupuestoService.guardarCapturaPresupuesto(presupuesto).subscribe(
            data => {
              console.log("REsultado de importar >>> ", data);
              // this.resultado = data.result;
              self.lModalExito = data.msg == "";
              self.mensajeModal = data.msg == "" ? "Registro Guardado con Éxito" : data.msg;
              self.asignarEstiloModal();
              self.largeModal.show();

              // Se carga el presupuesto una vez cargado
            },
            error => {
              self.mensajeModal = error;
              self.largeModal.show();
            },
            () => {
              self.mostrarBotonesFooter = false;
              self.cancelar();
              self.ventanaUpload.hide();
            }
          )
          
        });
    });

    // this.ventanaUpload.hide();
  }

}
