import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../servicios/UsuarioService';
import { ConfiguracionMenuService } from '../../servicios/configuracion-menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opciones-menu',
  templateUrl: './opciones-menu.component.html',
  styleUrls: ['./opciones-menu.component.css']
})
export class OpcionesMenuComponent implements OnInit {
  public usuario : any;
  public opciones : any;
  public filtrados : any;
  public agrupadores : any;
  public item : any;
  public colores = ["btn-primary", "btn-secondary", "btn-success", "btn-warning", "btn-danger", 
"btn-info", "btn-light","btn-dark", "btn-link"]

  constructor(private usuarioService: UsuarioService,
    private configMenuService: ConfiguracionMenuService,
    private router: Router) { }

  ngOnInit(): void {
    this.usuario =  this.usuarioService.getUserLogged();

    if (this.usuario != null){
    this.configMenuService.obtenerOpcionesMenu(this.usuario.Login).subscribe(
      data=>{
        this.opciones = data.data;
        this.filtrados = this.opciones;
        this.agrupadores = Array.from(
          new Set(this.filtrados.map(opc=>opc.TituloPadre)))
          .map(TituloPadre=> {return {
            TituloPadre : this.filtrados.find(f=>f.TituloPadre === TituloPadre).TituloPadre}
          });
      },
      error=>{
      }
    );
    }
  }

  asignarEstilo(index){
    let indice = index;
     this.colores.length;
    if (index >= this.colores.length){
      indice -= this.colores.length;
    }
    return {
    "btn": true,
    "btn-lg": true,
    "btn-primary": true,
    "col-sm-12": true,
    "mb-3": true
    }
  }
  asignarEstiloIcono(IdPagina){
   
   return {
   "icons": true,
   "d-block" : true,
   "font-2xl": true,
   "mt-4": true, 
   "icon-people": IdPagina == 1, 
   "icon-user" : IdPagina == 2,
   "cui-layers" : IdPagina == 3,
   "icon-puzzle": IdPagina == 4 || IdPagina == 5 || IdPagina == 9, 
   "icon-settings": IdPagina == 6,
   "icon-globe": IdPagina == 7,
   "icon-direction": IdPagina == 8,
   "icon-basket": IdPagina == 10 || IdPagina == 11,
   "fa-arrow-left": IdPagina == 12 || IdPagina == 13,
 }
  }

  abrirPagina(event, item){
    if (item != null && item.Url != ""){
      this.router.navigate(["Inicio/"+item.Url]);
    }
  }

  filtrarOpciones(event){
    const valor: string = event.target.value;
    this.filtrados = this.opciones.filter(element => {
      return (element.Titulo.toUpperCase().includes(valor.toUpperCase()) || valor === "")
    });
    this.agrupadores = Array.from(
      new Set(this.filtrados.map(opc=>opc.TituloPadre)))
      .map(TituloPadre=> {return {
        TituloPadre : this.filtrados.find(f=>f.TituloPadre === TituloPadre).TituloPadre}
      });
  }

  public filtrarXAgrupador(titulo):any{
   return  this.filtrados.filter(element => {
      return (element.TituloPadre.toUpperCase().includes(titulo.toUpperCase()))
    });
  }
}
