import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UsuarioService } from '../../servicios/UsuarioService';
import { SucursalService } from '../../servicios/sucursal.service';
import * as jQuery from 'jquery';
import { EmpresaService } from '../../servicios/empresa.service';

@Component({
  selector: 'app-agregar-sucursales-usuarios',
  templateUrl: './agregar-sucursales-usuarios.component.html',
  styleUrls: ['./agregar-sucursales-usuarios.component.css']
})
export class AgregarSucursalesUsuariosComponent implements OnInit {

  usuario : any ;
  usuarios: any = [];
  sucursales: any  = [];
  sucursalesAsig: any = [];
  sucursalesDisp : any = [];
  empresa: any = {};
  empresas: any = [];
  timer : any = null;
  menuAccionesVisible : boolean=  false;
  
  mensajeModal: string ;
  resultado : any;
  IdSucursalAgregar: string ="";
  IdSucursalRemover : string = "";
  
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  @ViewChild('ventanaBusquedaEmpresas', {static: false}) 
  public ventanaBusquedaEmpresas: ModalDirective;

  constructor(private usuarioService: UsuarioService,
    private sucursalService: SucursalService,
    private empresaService: EmpresaService) { }

  ngOnInit(): void {
    this.cancelar();
  }

  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.usuarios = [];
    this.ventanaBusqueda.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public buscarUsuarios(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.usuarioService.selectByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.usuarios = data.result;
        if (this.usuarios.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItemUsuario(IdUsuario){
    let tmpUsuario = this.usuarios.find(item => item.IdUsuario == IdUsuario);
    this.usuario = {
      IdUsuario : tmpUsuario.IdUsuario,
      Login : tmpUsuario.Login,
      IdEmpleado : tmpUsuario.IdEmpleado,
      NombreEmpleado: `${tmpUsuario.Nombre} ${tmpUsuario.ApellidoPaterno} ${tmpUsuario.ApellidoMaterno}` 
    };
    jQuery("#password").attr("disabled", "disabled");
    this.ventanaBusqueda.hide();
    if (this.empresa.IdEmpresa !== ""){
      this.obtenerSucursalesAsignadas(this.usuario.IdUsuario, this.empresa.IdEmpresa);
    }
  }

  public obtenerSucursalesAsignadas(IdUsuario, IdEmpresa){
    this.sucursalService.obtenerSucursalByIdEmpresa(IdEmpresa).subscribe(
      data_ => {
        this.sucursales = data_.result;
        this.sucursales.forEach(element => {
              element.RazonSocial = element.Empresa.RazonSocial;
          });
        
        this.sucursalService.obtenerSucursalesPorUsuarioYEmpresa(IdUsuario, IdEmpresa).subscribe(
          data => {
              this.sucursalesAsig = data.result;
              this.sucursalesDisp = [];
              this.sucursales.forEach(element => {
                if (element.IdEmpresa  == this.empresa.IdEmpresa){
                  let item = this.sucursalesAsig.find(item => item.IdSucursal  == element.IdSucursal);
                  if (item == null){
                    this.sucursalesDisp.push(element);
                  }
                }
              }); 
            }
        );
      }
    );
  }

  public cancelar(){
    this.usuario = {
      IdUsuario : '',
      Login : '',
      IdEmpleado : '',
      NombreEmpleado: '' 
    };
    this.empresa = {
      IdEmpresa : '',
      NombreEmpresa: ''
    };

    this.sucursalesAsig = this.sucursalesDisp = [];
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public removerItem(){
    if (this.IdSucursalRemover !== ""){
      let item = this.sucursalesAsig.find(item => item.IdSucursal == this.IdSucursalRemover);
      let index = this.sucursalesAsig.indexOf(item);
      this.sucursalesAsig.splice(index,1);
      this.sucursalesDisp.push(item);
      this.IdSucursalRemover = "";
    }
  }

  public removerItems(){
    this.IdSucursalAgregar = this.IdSucursalRemover = "";
    this.sucursalesDisp = this.sucursalesDisp.concat(this.sucursalesAsig);
    this.sucursalesAsig = [];
  }

  public agregarItem(){
    if (this.IdSucursalAgregar !== ""){
      let item = this.sucursalesDisp.find(item => item.IdSucursal == this.IdSucursalAgregar);
      let index = this.sucursalesDisp.indexOf(item);
      this.sucursalesDisp.splice(index,1);
      this.sucursalesAsig.push(item);
      this.IdSucursalAgregar = "";
    }
  }

  public agregarItems(){
    this.IdSucursalAgregar = this.IdSucursalRemover = "";
    this.sucursalesAsig = this.sucursalesAsig.concat(this.sucursalesDisp);
    this.sucursalesDisp = [];
  }

  public seleccionarItemAgregar(event){
    this.IdSucursalAgregar = event.target.value ;
  }

  public seleccionarItemRemover(event){
    this.IdSucursalRemover = event.target.value ;
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success")
      else
      jQuery("#ventanaModal").addClass("modal-danger")
  }

  public guardar(){
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;

    var mensaje:string ="";   
    
    if (this.empresa.IdUsuario === ""){
      mensaje = "Es necesario que seleccione un Usuario";
    }
    if (this.empresa.IdEmpresa === ""){
      mensaje = "Es necesario que seleccione una Empresa";
    }
    if (mensaje != ""){
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.mensajeModal = mensaje;
      this.largeModal.show();
      return;
    }

    this.sucursalService.guardarSucursalesAUsuario(this.usuario.IdUsuario, this.empresa.IdEmpresa, this.sucursalesAsig).subscribe(
      data=>{
        if (data.msg != ""){
          this.lModalExito = data.msg.indexOf("correctamente") >= 0;
          this.asignarEstiloModal();
          this.mensajeModal = data.msg;
          this.largeModal.show();
          this.cancelar();
        }
      },
      error=>{
        console.log(error);
      },
      ()=>{

      });
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public catalogoEmpresas(){
    this.textoVentanaBusqueda = "";
    this.empresas = [];
    this.ventanaBusquedaEmpresas.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public buscarEmpresas(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
    this.consultarRegistrosEmpresas(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistrosEmpresas(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.empresaService.obtenerEmpresasByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.empresas = data.result;
        if (this.empresas.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItemEmpresa(IdEmpresa){
    this.empresa = this.empresas.find(item => item.IdEmpresa == IdEmpresa);
    this.empresa.NombreEmpresa = `${this.empresa.RazonSocial} ${this.empresa.NombreComercial}`;
    this.ventanaBusquedaEmpresas.hide();
    if (this.usuario.IdUsuario !== ""){
      this.obtenerSucursalesAsignadas(this.usuario.IdUsuario, this.empresa.IdEmpresa);
    }
  }
}
