import { IComponentEventType } from './../../interfaces/component-event-type';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-btn-captura-gasto',
  templateUrl: './btn-captura-gasto.component.html',
  styleUrls: ['./btn-captura-gasto.component.css']
})
export class BtnCapturaGastoComponent implements OnInit {

  @Output()
  emmiter = new Subject<IComponentEventType>();

  @Input()
  data = {};

  constructor() { }

  ngOnInit(): void {
  }

  onAction() {
    this.emmiter.next({
      cmd: 'verCapturaGasto',
      data: this.data
    });
  }

  ngOnDestroy() {
    this.emmiter.unsubscribe();
  }

}
