import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionMenuService {

  public baseUrl:string = environment.apiUrl;

    public constructor (private _http: HttpClient, private router: Router){}

    public obtenerOpcionesMenu (username:string):Observable<any>{
        return this._http.get(`${this.baseUrl}ConfiguracionMenu/obtenerOpciones/${username}`);
    }

}
