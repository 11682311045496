import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GastosService {

  public apiUrl:string = environment.apiUrl;
  //private options = {headers: {'Content-Type': 'application/json'}};

  constructor(private http: HttpClient) { }

  public gastos(descripcion:string):Observable<any>{
    return this.http.get(`${this.apiUrl}Gastos`);
  }

  public guardarGasto(Gasto:any):Observable<any>{
    if (Gasto.Gasto_Id == 0) {
      return this.http.post<any>(`${this.apiUrl}Gastos`, Gasto)
    } else {
      return this.http.put(`${this.apiUrl}Gastos/${Gasto.Gasto_Id}`, JSON.stringify(Gasto));
    }
  }

  public gastoConSubGastos(Gasto_Id: number) : Observable<any> {
    return this.http.get(`${this.apiUrl}getGastoWithSubGastos?Gasto_Id=${Gasto_Id}`);
  }
}
