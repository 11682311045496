import { GastosService } from './../../servicios/gastos.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sub-gastos-a-gastos',
  templateUrl: './sub-gastos-a-gastos.component.html',
  styleUrls: ['./sub-gastos-a-gastos.component.css']
})
export class SubGastosAGastosComponent implements OnInit {

  // model
  gasto: any;
  gastos: any;
  gastosConSubGastos: any;
 // variables helpers
  subGastosAsignados: any = [];
  subGastosDisponibles: any = [];
  Id_a_Remover: string = "";
  Id_a_Agregar: string = "";

  // Modales
  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  mensajeModal: string ;
  resultado : any;
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  menuAccionesVisible : boolean=  false;

  constructor(private gastosService: GastosService) { }

  ngOnInit(): void {
    this.gasto = {
      Gasto_Id : "",
      Descripcion : "",
      Estatus : true
    };

    this.subGastosAsignados = [];
    this.subGastosDisponibles = [];
  }

  // Muestra la ventana para la Busqueda
  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.gastos = [];
    this.ventanaBusqueda.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  // Consulta los gastos de acuerdo al filtro seleccionado
  public buscarGastos(){
    this.resultadoBusquedaMensaje = "";
    this.gastosService.gastos(this.textoVentanaBusqueda).subscribe(
      data => {
        this.gastos = data;
        if (this.gastos.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    )
  }

  // Se selecciona un item del resultado de la busqueda
  public seleccionarItem(Gasto_Id){
    this.gasto = this.gastos.find(item => item.Gasto_Id == Gasto_Id);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
    // Obtenemos la informacion para la asignacion
    this.obtenerSubGastosAsignados(this.gasto.Gasto_Id);
  }

  // Se obtienen los sub gastos asignados al gasto, y los gastos que aun se pueden asignar
  public obtenerSubGastosAsignados(Gasto_Id){
    this.gastosService.gastoConSubGastos(Gasto_Id).subscribe(
      data => {
          this.subGastosAsignados = data.result.sub_gastos;
          this.subGastosDisponibles = data.subGastosDisponibles;
        }
    );
  }

  // Metodo para Guardar la Asignacion
  public guardar() {
    // Armamos el Objeto ara enviar a Guardar
    this.gasto = {
      Gasto_Id : this.gasto.Gasto_Id,
      Descripcion : this.gasto.Descripcion,
      Estatus : this.gasto.Estatus,
      SubGastos : this.subGastosAsignados
    };
    this.gastosService.guardarGasto(this.gasto).subscribe(
      data => {
        this.resultado = data.result;
        this.lModalExito = data.msg == "";
        this.mensajeModal = data.msg == "" ? "Registro Guardado con Éxito" : data.msg;
        this.asignarEstiloModal();
        this.largeModal.show();
        if (!this.lModalExito) this.cancelar();
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.cancelar();
      });
  }

  // Metodos para el manejo de las listas y la asignacion
  public removerItem(){
    if (this.Id_a_Remover !== ""){
      let item = this.subGastosAsignados.find(item => item.SubgGasto_Id == this.Id_a_Remover);
      let index = this.subGastosAsignados.indexOf(item);
      this.subGastosAsignados.splice(index,1);
      this.subGastosDisponibles.push(item);
      this.Id_a_Remover = "";
    }
  }

  public removerItems(){
    this.Id_a_Agregar = this.Id_a_Remover = "";
    this.subGastosDisponibles = this.subGastosDisponibles.concat(this.subGastosAsignados);
    this.subGastosAsignados = [];
  }

  public agregarItem(){
    if (this.Id_a_Agregar !== ""){
      let item = this.subGastosDisponibles.find(item => item.SubGasto_Id == this.Id_a_Agregar);
      let index = this.subGastosDisponibles.indexOf(item);
      this.subGastosDisponibles.splice(index,1);
      this.subGastosAsignados.push(item);
      this.Id_a_Agregar = "";
    }
  }

  public seleccionarItemAgregar(event){
    this.Id_a_Agregar = event.target.value ;
  }

  public seleccionarItemRemover(event){
    this.Id_a_Remover = event.target.value ;
  }

  public agregarItems(){
    this.Id_a_Agregar = this.Id_a_Remover = "";
    this.subGastosAsignados = this.subGastosAsignados.concat(this.subGastosDisponibles);
    this.subGastosDisponibles = [];
  }

  // Cancela la operacion actual y limpia la pantalla
  public cancelar(){
    this.gasto = {
      Gasto_Id : "",
      Descripcion : "",
      Estatus : true
    }
    this.subGastosAsignados = [];
    this.subGastosDisponibles = [];
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  // Establece la pantalla para un nuevo evento
  public nuevo(){
    this.gasto = {
      Area_Id : "",
      Descripcion : "",
      Estatus : true
    }
    this.mostrarBotonesFooter = true;
  }

  // Asigna el estido del modal de acuerdo a la respuesta de los servicios
  public asignarEstiloModal() {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success")
      else
      jQuery("#ventanaModal").addClass("modal-danger")

  }

  // Hack
  public iniciarTimer() {
    this.menuAccionesVisible = true;
  }


}
