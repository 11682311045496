import { ModalDirective } from 'ngx-bootstrap/modal';
import { AreasService } from './../../servicios/areas.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-gastos-a-areas',
  templateUrl: './gastos-a-areas.component.html',
  styleUrls: ['./gastos-a-areas.component.css']
})
export class GastosAAreasComponent implements OnInit {

  // model
  area: any;
  areas: any;
  areaConGastos: any;
 // variables helpers
  gastosAsignados: any = [];
  gastosDisponibles: any = [];
  Gasto_Id_a_Remover: string = "";
  Gasto_Id_a_Agregar: string = "";

  // Modales
  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusqueda', {static: false}) 
  public ventanaBusqueda: ModalDirective;

  mensajeModal: string ;
  resultado : any;
  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje : string = "";
  mostrarBotonesFooter : boolean = false;
  lModalExito: boolean = true;
  menuAccionesVisible : boolean=  false;

  constructor(private areasService: AreasService) { }

  ngOnInit(): void {
    this.area = {
      Area_Id : "",
      Descripcion : "",
      Estatus : true
    }
    this.gastosAsignados = [];
    this.gastosDisponibles = [];
  }

  // Muestra la ventana para la Busqueda
  public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    /// this.areas = [];
    this.ventanaBusqueda.show();
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  // Consulta las areas de acuerdo al filtro seleccionado
  public buscarAreas(){
    this.resultadoBusquedaMensaje = "";
    this.areasService.areas(this.textoVentanaBusqueda).subscribe(
      data => {
        this.areas = data;
        if (this.areas.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    )
  }

  // Se selecciona un item del resultado de la busqueda
  public seleccionarItem(Area_Id){
    this.area = this.areas.find(item => item.Area_Id == Area_Id);
    this.ventanaBusqueda.hide();
    this.mostrarBotonesFooter = true;
    // Obtenemos la informacion para la asignacion
    this.obtenerGastosAsignados(this.area.Area_Id);
  }

  // Se obtienen los gastos asignados al area, y los gastos que aun se pueden asignar
  public obtenerGastosAsignados(Area_Id){
    this.areasService.areaConGastos(Area_Id).subscribe(
      data => {
          this.gastosAsignados = data.result.gastos;
          this.gastosDisponibles = data.gastosDisponibles;
        }
    );
  }

  // Metodo para Guardar la Asignacion
  public guardar() {
    // Armamos el Objeto ara enviar a Guardar
    this.areaConGastos = {
      Area_Id : this.area.Area_Id,
      Descripcion : this.area.Descripcion,
      Estatus : this.area.Estatus,
      Gastos : this.gastosAsignados
    };
    this.areasService.guardarArea(this.areaConGastos).subscribe(
      data => {
        this.resultado = data.result;
        this.lModalExito = data.msg == "";
        this.mensajeModal = data.msg == "" ? "Registro Guardado con Éxito" : data.msg;
        this.asignarEstiloModal();
        this.largeModal.show();
        if (!this.lModalExito) this.cancelar();
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.cancelar();
      });
  }

  // Metodos para el manejo de las listas y la asignacion
  public removerItem(){
    if (this.Gasto_Id_a_Remover !== ""){
      let item = this.gastosAsignados.find(item => item.Gasto_Id == this.Gasto_Id_a_Remover);
      let index = this.gastosAsignados.indexOf(item);
      this.gastosAsignados.splice(index,1);
      this.gastosDisponibles.push(item);
      this.Gasto_Id_a_Remover = "";
    }
  }

  public removerItems(){
    this.Gasto_Id_a_Agregar = this.Gasto_Id_a_Remover = "";
    this.gastosDisponibles = this.gastosDisponibles.concat(this.gastosAsignados);
    this.gastosAsignados = [];
  }

  public agregarItem(){
    if (this.Gasto_Id_a_Agregar !== ""){
      let item = this.gastosDisponibles.find(item => item.Gasto_Id == this.Gasto_Id_a_Agregar);
      let index = this.gastosDisponibles.indexOf(item);
      this.gastosDisponibles.splice(index,1);
      this.gastosAsignados.push(item);
      this.Gasto_Id_a_Agregar = "";
    }
  }

  public seleccionarItemAgregar(event){
    this.Gasto_Id_a_Agregar = event.target.value ;
  }

  public seleccionarItemRemover(event){
    this.Gasto_Id_a_Remover = event.target.value ;
  }

  public agregarItems(){
    this.Gasto_Id_a_Agregar = this.Gasto_Id_a_Remover = "";
    this.gastosAsignados = this.gastosAsignados.concat(this.gastosDisponibles);
    this.gastosDisponibles = [];
  }

  // Cancela la operacion actual y limpia la pantalla
  public cancelar(){
    this.area = {
      Area_Id : "",
      Descripcion : "",
      Estatus : true
    }
    this.gastosAsignados = [];
    this.gastosDisponibles = [];
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  // Establece la pantalla para un nuevo evento
  public nuevo(){
    this.area = {
      Area_Id : "",
      Descripcion : "",
      Estatus : true
    }
    this.mostrarBotonesFooter = true;
  }

  // Asigna el estido del modal de acuerdo a la respuesta de los servicios
  public asignarEstiloModal() {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success")
      else
      jQuery("#ventanaModal").addClass("modal-danger")

  }

  // Hack
  public iniciarTimer() {
    this.menuAccionesVisible = true;
  }


}
