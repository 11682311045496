import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmpleadoService } from '../../servicios/empleado.service';
import { UsuarioService } from '../../servicios/UsuarioService';
import { environment } from '../../../environments/environment';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  public usuario: any = {};
  public usuarios: any = [];

  public empleado: any = {};
  public empleados: any = []

  public busqueda: string; 
  mensajeModal: string ;
  resultado : any;

  textoVentanaBusqueda: string = "";
  resultadoBusquedaMensaje: string = "";
  lModalExito: boolean = true;
  timer : any = null;
  menuAccionesVisible : boolean=  false;

  mostrarBotonesFooter : boolean = false;

  @ViewChild('largeModal', {static: false}) 
  public largeModal: ModalDirective;

  @ViewChild('ventanaBusquedaUsuarios', {static: false}) 
  public ventanaBusquedaUsuarios: ModalDirective;

  @ViewChild('ventanaBusquedaEmpleados', {static: false}) 
  public ventanaBusquedaEmpleados: ModalDirective;

  numeroMinimoCaracteres : number = environment.numMinimoCarateresPassword;
  contieneNcaracteres : boolean = false;
  contieneMayuscula: boolean = false;
  contieneMinuscula: boolean = false;
  contieneNumero: boolean = false;

  constructor(private usuarioService: UsuarioService,
    private empleadoService: EmpleadoService) { 
    this.usuario = {
      IdUsuario : "",
      Login : "",
      Password: "",
      IdEmpleado : "",
      NombreEmpleado: "",
      Empleado : "",
      FechaRegistro : "",
      FechaBloqueo : "",
      Estatus: "",
      Bloqueado: ""
    }
  }
  
  ngOnInit(): void {
    this.usuarioService.getUserLogged();
  }

   public realizarBusqueda() {
    this.textoVentanaBusqueda = "";
    this.usuarios = [];
    this.ventanaBusquedaUsuarios.show();
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public consultar(textoBusqueda){
    this.usuarioService.selectById(textoBusqueda).subscribe(
      data => {
        let resultado = data.result;
        
        if (resultado != null){
          this.usuario.IdUsuario = resultado.IdUsuario;
          this.usuario.Login = resultado.Login;
          this.usuario.Password = resultado.Password;
          this.usuario.IdEmpleado = resultado.IdEmpleado;
          this.usuario.Empleado = (resultado.Empleado != null ? resultado.Empleado.Nombre + ' ' +
          resultado.Empleado.ApellidoPaterno + ' ' + resultado.Empleado.ApellidoMaterno : "");
          console.log(resultado);
          this.mostrarBotonesFooter = true;
          
        }
        else{
          this.mensajeModal = "No se encontró el usuario";
          this.largeModal.show();
          this.cancelar();
        }
      }
    );
  }

  public cancelar(){
    this.usuario = {
      IdUsuario : "",
      Login : "",
      Password: "",
      IdEmpleado : "",
      Empleado : "",
      FechaRegistro : "",
      FechaBloqueo : "",
      Estatus: "",
      Bloqueado: ""
    };
    jQuery("#password").removeAttr("disabled");
    this.contieneMayuscula = this.contieneMinuscula =
    this.contieneNcaracteres = this.contieneNumero = false;
    this.mostrarBotonesFooter = false;
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }

  public nuevo(){
    this.cancelar();
    this.usuario.IdUsuario = "0";
    this.usuario.Estatus = 1;
    this.usuario.Bloqueado = 0;
    this.mostrarBotonesFooter = true;
    jQuery("#password").removeAttr("disabled");
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
  }

  public buscarUsuarios(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistros(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistros(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.usuarioService.selectByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.usuarios = data.result;
        if (this.usuarios.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItemUsuario(IdUsuario){
    let tmpUsuario = this.usuarios.find(item => item.IdUsuario == IdUsuario);
    this.usuario = {
      IdUsuario : tmpUsuario.IdUsuario,
      Login : tmpUsuario.Login,
      Password: tmpUsuario.Password,
      IdEmpleado : tmpUsuario.IdEmpleado,
      NombreEmpleado: `${tmpUsuario.Nombre} ${tmpUsuario.ApellidoPaterno} ${tmpUsuario.ApellidoMaterno}` ,
      FechaRegistro : tmpUsuario.FechaRegistro,
      FechaBloqueo : tmpUsuario.FechaBloqueo,
      Estatus: tmpUsuario.Estatus ,
      Bloqueado: tmpUsuario.Bloqueado 
    };
    jQuery("#password").attr("disabled", "disabled");
    this.ventanaBusquedaUsuarios.hide();
    this.mostrarBotonesFooter = true;
  }

  public catalogoEmpleados(){
    this.textoVentanaBusqueda = "";
    this.empleados = [];
    this.ventanaBusquedaEmpleados.show();
  }

  public buscarEmpleados(){
    if (this.textoVentanaBusqueda == ""){
      this.resultadoBusquedaMensaje = "Es necesario que proporcione una valor a buscar";
    }
    else {
      this.consultarRegistrosEmpleados(this.textoVentanaBusqueda);
    }
  }

  public consultarRegistrosEmpleados(textoVentanaBusqueda){
    this.resultadoBusquedaMensaje = "";
    this.empleadoService.obtenerEmpleadosByDescripcion(textoVentanaBusqueda).subscribe(
      data => {
        this.empleados = data.result;
        if (this.empleados.length == 0){
          this.resultadoBusquedaMensaje = "No se encontraron registros";
        }
      }
    );
  }

  public seleccionarItemEmpleado(IdEmpleado){
    let empleado = this.empleados.find(item => item.IdEmpleado == IdEmpleado);
    this.usuario.IdEmpleado = empleado.IdEmpleado;
    this.usuario.NombreEmpleado = `${empleado.Nombre} ${empleado.ApellidoPaterno} ${empleado.ApellidoMaterno}`; 
    this.ventanaBusquedaEmpleados.hide();
    this.mostrarBotonesFooter = true;
  }

  public guardar() {
    jQuery("#btnOpciones").click();
    this.menuAccionesVisible = false;
    var mensaje:string ="";   
    if (this.usuario.Login == ""){
      mensaje = "El campo Login es requerido";
    }
    else if (this.usuario.IdEmpleado == ""){
      mensaje = "El campo Empleado es requerido";
    }
    else if (this.usuario.Password == ""){
      mensaje = "El campo password es requerido";
    }
    if (this.usuario.IdUsuario === "0" && (!this.contieneMayuscula || !this.contieneMinuscula || 
      !this.contieneNumero || !this.contieneNcaracteres)){
      mensaje = "El contraseña proporcionada no cumple con las reglas mínimas requeridas";
    }
    if (mensaje != ""){
      this.mensajeModal = mensaje;
      this.lModalExito = false;
      this.asignarEstiloModal();
      this.largeModal.show();
      return;
    }

    this.usuarioService.guardar(this.usuario).subscribe(
      data=>{
        if (data.msg != ""){
          this.lModalExito = data.msg.indexOf("correctamente") >= 0;
          this.asignarEstiloModal();
          this.mensajeModal = data.msg;
          this.largeModal.show();
        }
      },
      error=>{
        console.log(error);
      },
      ()=>{
        this.cancelar();
        this.mostrarBotonesFooter = false;
      }
    );
  }

  public validarPassword(event){
    if (this.usuario.IdUsuario == 0 ){
      this.contieneNcaracteres = event.target.value.length >= this.numeroMinimoCaracteres;
      this.contieneMayuscula = this.usuarioService.validarAlMenosUnaMayuscula(event.target.value);
      this.contieneMinuscula = this.usuarioService.validarAlMenosUnaMinuscula(event.target.value);
      this.contieneNumero = this.usuarioService.validarAlMenosUnNumero(event.target.value);
    }
  }

  public asignarEstiloModal()
  {
    jQuery("#ventanaModal").removeClass("modal-success");
    jQuery("#ventanaModal").removeClass("modal-danger");
    if (this.lModalExito)
      jQuery("#ventanaModal").addClass("modal-success");
    else
      jQuery("#ventanaModal").addClass("modal-danger");
  }

  public iniciarTimer(){
    this.menuAccionesVisible = true;
  }

  public ocultarMenu(){
    if (this.menuAccionesVisible){
      jQuery("#btnOpciones").click();
      this.menuAccionesVisible = false;
    }
  }
}
